import { AnalyticProps } from "./Analytics";

const detailLossReportSubmission: AnalyticProps = {
  action_value: 'Submit Details Loss Report click',
  label_value: 'SUBMIT',
  event_detail: 'Details Loss Report',
  event_action: 'report run',
  page_name: 'Detail Loss',
  login_id: "",
  date: "",
  user_type: "",
  account_identification_type: "",
  capability: "",
  appid_cd: "",
  appcd: "",
};

const financialSummaryReportSubmission: AnalyticProps = {
  action_value: 'Submit Financial Summary Report click',
  label_value: 'SUBMIT',
  event_detail: 'Financial Summary Report',
  event_action: 'report run',
  page_name: 'Financial Summary',
  login_id: "",
  date: "",
  user_type: "",
  account_identification_type: "",
  capability: "",
  appid_cd: "",
  appcd: "",
};

const detailChangesReportSubmission: AnalyticProps = {
  action_value: 'Submit Detail Chagnes Report click',
  label_value: 'SUBMIT',
  event_detail: 'Detail Chagnes Report',
  event_action: 'report run',
  page_name: 'Detail Chagnes',
  login_id: "",
  date: "",
  user_type: "",
  account_identification_type: "",
  capability: "",
  appid_cd: "",
  appcd: "",
};

const claimLagAnalysisReportSubmission: AnalyticProps = {
  action_value: 'Submit Claim Lag Analysis Report click',
  label_value: 'SUBMIT',
  event_detail: 'Claim Lag Analysis Report',
  event_action: 'report run',
  page_name: 'Claim Lag Analysis',
  login_id: "",
  date: "",
  user_type: "",
  account_identification_type: "",
  capability: "",
  appid_cd: "",
  appcd: "",
};

const insuredRetentionReportSubmission: AnalyticProps = {
  action_value: 'Submit Insured Retention Report click',
  label_value: 'SUBMIT',
  event_detail: 'Insured Retention Report',
  event_action: 'report run',
  page_name: 'Insured Retention',
  login_id: "",
  date: "",
  user_type: "",
  account_identification_type: "",
  capability: "",
  appid_cd: "",
  appcd: "",
}

const nationalProgramsReportSubmission: AnalyticProps = {
  action_value: 'Submit National Programs Report click',
  label_value: 'SUBMIT',
  event_detail: 'National Programs Report',
  event_action: 'report run',
  page_name: 'National Programs',
  login_id: "",
  date: "",
  user_type: "",
  account_identification_type: "",
  capability: "",
  appid_cd: "",
  appcd: "",
}

const policySummaryReportSubmission: AnalyticProps = {
  action_value: 'Submit Policy Summary Report click',
  label_value: 'SUBMIT',
  event_detail: 'Policy Summary Report',
  event_action: 'report run',
  page_name: 'Policy Summary',
  login_id: "",
  date: "",
  user_type: "",
  account_identification_type: "",
  capability: "",
  appid_cd: "",
  appcd: "",
}

const savedParametersReportSubmission: AnalyticProps = {
  action_value: 'Submit Saved Parameters Report click',
  label_value: 'SUBMIT',
  event_detail: 'Saved Parameters Report',
  event_action: 'report run',
  page_name: 'Saved Parameters',
  login_id: "",
  date: "",
  user_type: "",
  account_identification_type: "",
  capability: "",
  appid_cd: "",
  appcd: "",
}

const drillDownReportSubmission: AnalyticProps = {
  action_value: 'Submit Drilldown Report click',
  label_value: 'SUBMIT',
  event_detail: 'Dirlldown Report',
  event_action: 'report run',
  page_name: 'Drilldown',
  login_id: "",
  date: "",
  user_type: "",
  account_identification_type: "",
  capability: "",
  appid_cd: "",
  appcd: "",
}

const oneClickReportSubmission: AnalyticProps = {
  action_value: 'Submit One-Click Reports',
  label_value: 'One-Click SUBMIT',
  event_detail: 'One-Click Report',
  event_action: 'report run',
  page_name: 'Home',
  login_id: "",
  date: "",
  user_type: "",
  account_identification_type: "",
  capability: "",
  appid_cd: "",
  appcd: "",
};

export type DetailTypes =
  "detailLossReportSubmission" |
  "financialSummaryReportSubmission" |
  "detailChangesReportSubmission" |
  "claimLagAnalysisReportSubmission" |
  "insuredRetentionReportSubmission" |
  "nationalProgramsReportSubmission" |
  "policySummaryReportSubmission" |
  "savedParametersReportSubmission" |
  "drillDownReportSubmission" |
  "oneClickReportSubmission"

export default (detail: DetailTypes): AnalyticProps => {
  let config: AnalyticProps = { action_value: "", label_value: "", event_detail: "", event_action: "", page_name: "" };
  switch (detail) {
    case "detailLossReportSubmission": { config = detailLossReportSubmission; break }
    case "financialSummaryReportSubmission": { config = financialSummaryReportSubmission; break }
    case "detailChangesReportSubmission": { config = detailChangesReportSubmission; break }
    case "claimLagAnalysisReportSubmission": { config = claimLagAnalysisReportSubmission; break }
    case "insuredRetentionReportSubmission": { config = insuredRetentionReportSubmission; break }
    case "nationalProgramsReportSubmission": { config = nationalProgramsReportSubmission; break }
    case "policySummaryReportSubmission": { config = policySummaryReportSubmission; break }
    case "savedParametersReportSubmission": { config = savedParametersReportSubmission; break }
    case "drillDownReportSubmission": { config = drillDownReportSubmission; break }
    case "oneClickReportSubmission": { config = oneClickReportSubmission; break }
    
    default: break;
  }
  console.log('config output', config);
  return { ...config };
}
