import { Spinner } from '@chakra-ui/react';
import { ReactComponent as ClaimActivityEAlertsCloseIcon } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/x-1-12.svg';
import { Dispatch, SetStateAction, useState } from 'react';
import { useGetEmailSubscriptionsQuery, useSubscribeEmailAlertMutation, useUnsubscribeEmailAlertMutation } from '../../../features/emailAlerts/emailAlertApiSlice';
import useAuth from '../../../hooks/useAuth';
import { LoadingSkeleton } from '../Common/LoadingSkeleton';
type ClaimsActivityEAlertsProps = {
  setShowClaimsActivityEAlertDialog: Dispatch<SetStateAction<boolean>>;
};
const ClaimsActivityEAlerts = ({ setShowClaimsActivityEAlertDialog }: ClaimsActivityEAlertsProps) => {
  const { profileData } = useAuth();

  let shouldSkip = (profileData === undefined || profileData === null || profileData?.length === 0) ||
    (profileData?.length > 0 && (
      profileData[0]?.Role.IsInternalUser
    ));

  const queryData = {
    UserInformation: profileData[0],
  };

  const {
    IsBroker
  } = profileData?.[0]?.Role ?? {};

  const {
    data,
    isLoading
  } = useGetEmailSubscriptionsQuery(queryData, { skip: shouldSkip });
  const isSubscribed = data?.result
  const [subscribe] = useSubscribeEmailAlertMutation()
  const [unsubscribe] = useUnsubscribeEmailAlertMutation()
  const [showLoader, setShowLoader] = useState(false)
  async function claimEmailActivities() {
    setShowLoader(true)
    if (isSubscribed) {
      await unsubscribe(queryData)
    } else {
      await subscribe(queryData)
    }
    setShowLoader(false)
    setShowClaimsActivityEAlertDialog(false)
  }

  return (
    //Need to add Agent and account user popup
    <div role='dialog' className='tds-modal tds-modal__dialog--medium' aria-label='claim activity email alerts modal'>
      <div role='document' className='tds-modal__dialog-container'>
        <div className='tds-modal__dialog-header'>
          <h2 className='tds-modal__dialog-title' style={{ color: '#46494d' }}>
            Claims Activity e-Alerts
          </h2>
        </div>

        <div className='tds-modal__dialog-body' style={{ paddingBottom: '2rem' }}>
          <div className='tds-modal__dialog-body-content'>
            {isLoading ? <LoadingSkeleton width={540} />
              :
              <>
                <p style={{ fontSize: '1.1rem' }}>
                  e-Alerts: This will send you a daily email alert for any claim activity<br></br>
                  {IsBroker ? 'on the accounts in you personal book of business': 'on your account'} to your email address: {profileData[0].UserEmail}.
                </p>
                <p style={{ fontSize: '1.1rem' }}>Thank you for showing interest in e-Alerts.</p>
                <p style={{ fontSize: '1.1rem' }}>Do you want to {isSubscribed ? "unsubscribe" : "subscribe"} to Daily claim activity e-Alerts?</p>

              </>
            }
          </div>

          <div className='claims-activity-e-alerts-button-div'>
            <div style={{ margin: '0 auto' }}>
              <button onClick={claimEmailActivities} className='tds-button--primary claims-activity-e-alerts-button' style={{ marginBottom: '1rem' }} type='button'>
               
               {showLoader ? <Spinner color="white" size="md" /> : "YES"}
              </button>
            </div>
            <div style={{ margin: '0 auto' }}>
            <button onClick={() => setShowClaimsActivityEAlertDialog(false)} className='tds-button--secondary claims-activity-e-alerts-button' type='button'>
                NO
              </button>
            </div>
          </div>

          <button onClick={() => setShowClaimsActivityEAlertDialog(false)} className='tds-modal__dismiss' aria-label='Dismiss Claim Activity e-Alerts' type='button'>
            <ClaimActivityEAlertsCloseIcon alt='Close Alert' />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ClaimsActivityEAlerts;
