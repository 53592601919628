import { ReactComponent as AccountIdentificationAlertCloseIcon } from "@trv-ebus/tds-icons/dist/assets/icons/functional/svg/x-1-12.svg";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useSearchParams } from "react-router-dom";
import { addSuccessMessage } from "../../features/claimActivity/claimActivityErrorMsgSlice";
import useAuth from "../../hooks/useAuth";
import { RootState } from "../../store";
import { ErrorHandler } from "../Errors/ErrorHandler";
import AccountIdentification from "../Shared/AccountIdentification/AccountIdentification";
import { TdsLoadingSpinner } from "@trv-tds/react";
import { AppContext } from "../../context/appContext";

const ReportLayout = () => {
  const [reportingOptionTitle, setReportingOptionTitle] = useState<string>("");
  const [searchParams, _] = useSearchParams();
  const page = searchParams.get("page");

  const defaultAppConfig = { APPLICATION: undefined }
  const { appConfig: { APPLICATION } = defaultAppConfig } = useContext(AppContext)
  const { profileData, isInternalUser } = useAuth();
  const successMsg = useSelector(
    (state: RootState) => state.claimActivity.successMessage
  );
  const ref = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (successMsg === "success") scrollToElement();
  }, [successMsg]);

  const scrollToElement = () => {
    ref?.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    dispatch(addSuccessMessage(""));
    if (page) {
      switch (page) {
        case "DetailLoss":
          setReportingOptionTitle("Detail Loss");
          break;
        case "FinancialSummary":
          setReportingOptionTitle("Financial Summary");
          break;
        case "DetailChanges":
          setReportingOptionTitle("Detail Changes");
          break;
        case "ClaimLagAnalysis":
          setReportingOptionTitle("Claim Lag Analysis");
          break;
        case "InsuredRetention":
          setReportingOptionTitle("Insured Retention");
          break;
        case "PolicySummary":
          setReportingOptionTitle("Policy Summary");
          break;
        case "NationalPrograms":
          setReportingOptionTitle("National Programs");
          break;
        case "SavedParameters":
          setReportingOptionTitle("Saved Parameters");
          break;
        default:
          setReportingOptionTitle("");
          break;
      }
    } else {
      setReportingOptionTitle("");
    }
  }, [page]);

  const errorMessages: string[] = useSelector(
    (state: RootState) => state.errors.errorMessages
  );

  if (page === "SavedParameters") {
      return (
        <>
            {errorMessages && (
              <ErrorHandler
                errors={errorMessages}
                isOpen={errorMessages.length > 0}
              />
            )}
          <Outlet />
        </>
      );
    }
     return (
    <>
      <div ref={ref} className="homeform-main-div">
        {errorMessages && (
          <ErrorHandler
            errors={errorMessages}
            isOpen={errorMessages.length > 0}
          />
        )}
          <>
            {profileData.length === 0 &&(
            <div>
              <TdsLoadingSpinner label="Processing" size="xxx-large" label-displayed overlay centered></TdsLoadingSpinner>
            </div>
            )}
            <div className="report-options-inner-div-title-act-identification" style={{ minHeight: 'auto' }}>
              <h2
                className="tds-h2--article"
                hidden={reportingOptionTitle === "" ? true : false}
                style={{
                  color: "#46494d",
                  fontSize: "1.5rem",
                  marginBottom: "1rem",
                }}
              >
                {`${reportingOptionTitle} Reporting Options`}
              </h2>
              <div
                id="save-success-message"
                aria-atomic="true"
                className="tds-alert--success"
                role="alert"
                hidden={successMsg !== "success" && successMsg !== "updated"}
              >
                <p style={{ fontSize: "14px" }}>
                  Report parameter has been {successMsg==="updated"?successMsg:"saved"} successfully.
                </p>
                <button
                  onClick={() => {
                    dispatch(addSuccessMessage(""));
                  }}
                  aria-label="Dismiss alert."
                  className="tds-alert__dismiss"
                >
                  <AccountIdentificationAlertCloseIcon alt="Close Alert" />
                </button>
              </div>

              <div
                id="save-success-message"
                aria-atomic="true"
                className="tds-alert--success"
                role="alert"
                hidden={successMsg !== "duplicate"}
              >
                <p style={{ fontSize: "14px" }}>Report name already exists.</p>

                <button
                  onClick={() => {
                    dispatch(addSuccessMessage(""));
                  }}
                  aria-label="Dismiss alert."
                  className="tds-alert__dismiss"
                >
                  <AccountIdentificationAlertCloseIcon alt="Close Alert" />
                </button>
              </div>
              <AccountIdentification />
            </div>
          </>
        <Outlet />
      </div>
    </>
  );
};
export default ReportLayout;
