import { Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addErrorMessage, removeErrorMessage } from '../../../features/claimActivity/claimActivityErrorMsgSlice';
import { getCurrentDateMinusTwo } from '../../../features/utils/utils';
import useAuth from '../../../hooks/useAuth';
import useClaimActivityListData from '../../../hooks/useClaimActivityListData';
import { RootState } from '../../../store';
import { AccIdentification } from '../AgentAccountSearch/agentAccount';
import { Loader } from '../Common/Loader';
import ClaimActivityAccountsModal from './ClaimActivityAccountsModal';
import { DataTable } from './DataTable';

enum ResultType {
  CURRENT_DAY,
  CURRENT_MONTH
}
interface TableHeaders {
  Id: string
  CATEGORY_DESC: string
  CLAIM_COUNT?: number
  SUM_INC_AMT?: number
}

const ClaimActivityTable = () => {
  const dispatch = useDispatch();
  const { profileData } = useAuth();
  const userAccountIdentification: AccIdentification = useSelector((state: RootState) => state.agentAccount.accountIdentification);
  const { accountIdentificationSelections } = userAccountIdentification;
  const claimActivityTableRef = useRef<null | HTMLDivElement>(null);
  const [resultType, setResultType] = useState(ResultType.CURRENT_DAY)
  const [hasErrors, setHasErrors] = useState<boolean>(true);
  const [categoryCode, setCategoryCode] = useState<string>('N');
  const [showClaimActivityAccountsModal, setShowClaimActivityAccountsModal] = useState<boolean>(false);

  function replaceNonNumeric(numStr: any) {
    return String(numStr).replace(/[^0-9]/g, '')
  }
  function toLocaleString(numStr: any) {
    return numStr === null ? null : `$${Number(replaceNonNumeric(numStr)).toLocaleString()}`
  }

  const columnHelper = createColumnHelper<TableHeaders>();

  const columns = [
    columnHelper.accessor("CATEGORY_DESC", {
      cell: (info) => info.getValue(),
      header: "Activity Type"
    }),
    columnHelper.accessor("CLAIM_COUNT", {
      cell: (info) => info.getValue(),
      header: "Claim Count",
      meta: {
        isNumeric: true
      }
    }),
    columnHelper.accessor("SUM_INC_AMT", {
      cell: (info) => toLocaleString(info.getValue()),
      header: "Incurred Dollars",
      meta: {
        isNumeric: true
      }
    })
  ];

  useEffect(() => {
    if (claimActivityTableRef.current) {
      claimActivityTableRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  });


  const { claimActivityListData, isClaimActivityListLoading, isClaimActivityListSuccess, getClaimActivityListError, isClaimActivityListError } = useClaimActivityListData();

  if (isClaimActivityListError) {
    console.log("error retrieving claim activity table data:" + getClaimActivityListError);
  }
  useEffect(() => {
    if (!isClaimActivityListLoading && isClaimActivityListSuccess && claimActivityListData) {
      let agentAccountList: any[] = [];
      if (claimActivityListData.messages[0].code !== '0') {
        setHasErrors(true);
        dispatch(addErrorMessage(claimActivityListData.messages[0].description));
      } else {
        setHasErrors(false);
        dispatch(removeErrorMessage(""));
      }
    }
  }, [claimActivityListData]);


  const currentResultType = ResultType[resultType]
  const tableData = !claimActivityListData?.result || !claimActivityListData?.result[currentResultType] ? null : claimActivityListData.result[currentResultType];
  const onRowClick = (data: any) => {
    console.log("Row Click", data)
    setCategoryCode(data.CATEGORY_CD);
    document.body.style.position = 'fixed';
    document.body.style.top = `-${window.scrollY}px`;
    setShowClaimActivityAccountsModal(true)
  }

  return (
    <>
      {isClaimActivityListLoading && <Loader p={50} />}
      {claimActivityListData && !hasErrors && (
        <div ref={claimActivityTableRef} className='claim-activity-table-main-div'>
          <Stack h={30} spacing={4} direction='row'>
            <Text fontSize="md">Activity for</Text>
            <RadioGroup
              size="sm"
              value={resultType.toString()}>
              <Stack spacing={8} direction='row'>
                <Radio value={ResultType.CURRENT_DAY.toString()} onChange={() => setResultType(ResultType.CURRENT_DAY)} >Today <i>(as of {getCurrentDateMinusTwo()})</i></Radio>
                <Radio value={ResultType.CURRENT_MONTH.toString()} onChange={() => setResultType(ResultType.CURRENT_MONTH)} >Current Month</Radio>
              </Stack>
            </RadioGroup>
          </Stack>

          <div className='claim-activity-table-account-name-div'>
            <p style={{ margin: 0, paddingLeft: '0.5625rem' }}>
              {
                (accountIdentificationSelections.length === 0 && profileData[0].Role.IsBroker && !profileData[0].Role.IsInternalUser)
                  ? "My Accounts"
                  : (claimActivityListData.result !== null ? claimActivityListData.result.LossesCriteria[0].AccountName : "")
              }
            </p>
          </div>
          <DataTable onRowClick={onRowClick} columns={columns} data={tableData} />
          {showClaimActivityAccountsModal && <ClaimActivityAccountsModal categoryCode={categoryCode} setShowClaimActivityAccountsModal={setShowClaimActivityAccountsModal} activityPeriod={resultType} />}
        </div>
      )}
    </>
  );
};
export default ClaimActivityTable;
