import { FormControl, FormLabel, Input, Stack, Text } from '@chakra-ui/react';
import { TdsField } from '@trv-tds/react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BLANK_FILTER, BLANK_GROUP } from '../../common/constants';
import { FilterOption, GroupOption, ReportData } from '../../common/types';
import { AppContext } from '../../context/appContext';
import { addAccountErrorMessage, addSuccessMessage, setSubmitClicked, showClaimActivityErrorMessage } from '../../features/claimActivity/claimActivityErrorMsgSlice';
import { addDateRequiredErrorMessage, addHistoryDateRequiredErrorMessage } from '../../features/errors/errorReducer';
import { useGetPrefillDataQuery } from '../../features/utils/prefillApiSlice';
import { dollarTypeOperatorOptions, dollarTypeOptions, getGroups, getHistoryDate, getHistoryDateSavedParams, getLargeLossType, getLargeLossValue, getOper, getOperator, isValidDate, validDateFormat, validYear, yearInRange } from '../../features/utils/utils';
import useAuth from '../../hooks/useAuth';
import useClaimActivityListData from '../../hooks/useClaimActivityListData';
import { RootState } from '../../store';
import { StackContainer } from '../Layout/StackContainer';
import PolicyNumber from '../Shared/AccountIdentification/PolicyNumber';
import AgentAccountSearch from '../Shared/AgentAccountSearch/AgentAccountSearch';
import { AccIdentification } from '../Shared/AgentAccountSearch/agentAccount';
import { resetAgentAllSelectedAccounts, updateAccountIdentification } from '../Shared/AgentAccountSearch/agentAccountSlice';
import { DateSelectionCriteria } from '../Shared/FormSections/DateSelectionCriteria';
import { FilterSection } from '../Shared/FormSections/FilterSection';
import { GroupBySection } from '../Shared/FormSections/GroupBySection';
import { SubmissionSection } from '../Shared/FormSections/SubmissionSection';
import ReportFilteringResults from '../Shared/ReportFiltering/ReportFilteringResults';
import WCReportFilteringResults from '../Shared/ReportFiltering/WCReportFilteringResults';
import { useGetDropdownListQuery } from '../../features/utils/utisApiSlice';
import { useLocation } from 'react-router-dom';
import { useUpdateParametersMutation } from '../../features/savedParameters/savedParametersApiSlice';
import moment from 'moment';
import { useAnalytics } from '@trv/bi-digital-web-analytics';
import { clickAnalytics } from '../Shared/Analytics/Analytics';
import getAnalyticsConfig from "../Shared/Analytics/AnalyticsConfig";

const DetailChanges = () => {
  const location = useLocation();
  const [filteringOperator, setFilteringOperator] = useState<string>("");
  const [filteringOption, setFilteringOption] = useState<string>("");
  const [filteringAmount, setFilteringAmount] = useState<string>("");
  const [filteringEndingAmount, setFilteringEndingAmount] = useState<string>("");
  const [isFilteringDirty, setIsFilteringDirty] = useState<boolean>(false);
  const [largeLossValue, setLargeLossValue] = useState("");
  const { profileData } = useAuth();
  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [historyDate, setHistoryDate] = useState<string>("");
  const [historyDate2, setHistoryDate2] = useState<string>("");
  const [dateHasErrors, setDateHasErrors] = useState<boolean>(false);
  const [filters, setStateFilters] = useState<FilterOption[]>([BLANK_FILTER]);
  const [orgLevelData, setOrgLevelData] = useState<any>();
  const [acctOrgLevelData, setAcctOrgLevelData] = useState<any>();
  const groupLocation = getGroups(location?.state?.groups) || [BLANK_GROUP];
  const [groups, setGroups] = useState<GroupOption[]>(groupLocation);
  const [showReportFilteringResultsModal, setShowReportFilteringResultsModal] =
    useState<boolean>(false);
  const userAccountIdentification: AccIdentification = useSelector(
    (state: RootState) => state.agentAccount.accountIdentification
  );
  const [groupByHasErrors, setGroupByHasErrors] = useState<boolean>();
  const { claimActivityErrorMessage: claimActivityListError, showClaimActivityError } = useSelector((state: RootState) => state.claimActivity);
  const { accountIdentificationSelections } = userAccountIdentification;
  const [activeFilter, setActiveFilter] = useState<FilterOption | undefined>();
  const chosenFilter: FilterOption = useSelector(
    (state: RootState) => state.filterLookup.activeFilter
  );
  const showModal = () => setShowReportFilteringResultsModal(true);
  const [dropDownElement, setDropDownElement] = useState<string>("Incurred");
  const [resetDates, setResetDates] = useState<boolean | undefined>(false);
  const [reportName, setReportName] = useState<string>("Detail Changes");
  const [reportHeading, setReportHeading] = useState<string>("");
  const [reportTypeSelected, setReportTypeSelected] = useState<string>("pdf");
  const [saveResponse, setSaveResponse] = useState<number>(0);
  const [missingReportName, setMissingReportName] = useState<boolean>(false);
  const [previousSelectionsCount, setPreviousSelectionsCount] =
    useState<number>(0);
  const [clearMsg, setClearMsg] = useState<boolean>(false);
  const [clearedCriteriaMsg, setClearedCriteriaMsg] = useState<boolean>(false);
  const filteredGroups = [...groups].filter(
    (_group) => _group.field !== "" || _group.label !== ""
  );
  const filteredFilters = [...filters].filter(
    (_filter) => _filter.field !== "" || _filter.dbValue !== ""
  );
  const defaultAppConfig = { REACT_APP_ST_CLAIM_REPORT_URL: undefined }
  const { appConfig: { REACT_APP_ST_CLAIM_REPORT_URL } = defaultAppConfig } = useContext(AppContext)

  const { data: dropDownData, isError: apiIsError, error: apiError } = useGetDropdownListQuery();
  const analytics = useAnalytics();

  //Note this is needed because we validate account information with the claim activity service when user enters it in account ID section
  useClaimActivityListData();

  const setFilters = (filters: FilterOption[]) => {
    setStateFilters(filters);
  };
  const ref = useRef<HTMLInputElement>(null);
  const dateRef = useRef<HTMLInputElement>(null);
  const filteringRef = useRef<HTMLInputElement>(null);
  const topRef = useRef<HTMLInputElement>(null);
  const groupRef = useRef<HTMLInputElement>(null);
  const [paramId, setParamId] = useState<number>();
  const [savedParamsAction, setSavedParamsAction] = useState<string>("");

  const formattedFilters = [...filters].map((filter) => {
    const newFilter = {
      ...filter,
      operatorError: false,
      dateFormatError: false,
      dateInvalidError: false,
      yearInvalidError: false,
      yearFormatError: false,
      invalidDateOperatorError: false,
      multipleValuesGTError: false,
      multipleValuesLTError: false,
    };
    if (
      (newFilter.field === "Date of Hire (WC)" ||
        newFilter.field === "Date of Loss" ||
        newFilter.field === "Date Notice Recv'd" ||
        newFilter.field === "Policy Eff Date" ||
        newFilter.field === "Accident Year" ||
        newFilter.field === "Policy Year") &&
      (newFilter.operator === "Contains" ||
        newFilter.operator === "Omits" ||
        newFilter.operator === "Starts With")
    ) {
      newFilter.invalidDateOperatorError = true;
    }
    if (
      newFilter.field !== "" &&
      newFilter.value.length > 0 &&
      newFilter.isDirty
    ) {
      if (newFilter.operator === "") {
        newFilter.operatorError = true;
      }
      if (
        newFilter.field === "Date of Hire (WC)" ||
        newFilter.field === "Date of Loss" ||
        newFilter.field === "Date Notice Recv'd" ||
        newFilter.field === "Policy Eff Date"
      ) {
        if (
          newFilter.value.length === 8 &&
          newFilter.value.indexOf("/") === -1
        ) {
          newFilter.value =
            newFilter.value.substring(0, 2) +
            "/" +
            newFilter.value.substring(2, 4) +
            "/" +
            newFilter.value.substring(4, 8);
        }
        if (!isValidDate(newFilter.value)) {
          newFilter.dateInvalidError = true;
        }
        if (!validDateFormat(newFilter.value)) {
          newFilter.dateFormatError = true;
        }
      }
    }
    if (
      newFilter.isDirty &&
      (newFilter.field === "Accident Year" || newFilter.field === "Policy Year")
    ) {
      if (!validYear(newFilter.value)) {
        newFilter.yearFormatError = true;
      }
      if (!yearInRange(newFilter.value)) {
        newFilter.yearInvalidError = true;
      }
    }
    if (newFilter.isDirty && (newFilter.field === "Date of Hire (WC)" ||
      newFilter.field === "Date of Loss" ||
      newFilter.field === "Date Notice Recv'd" ||
      newFilter.field === "Policy Eff Date"
    )) {
      if (!isValidDate(newFilter.value)) {
        newFilter.dateInvalidError = true;
      }
      if (!validDateFormat(newFilter.value)) {
        newFilter.dateFormatError = true;
      }

    }
    if (
      filter.operator === "Greater Than" &&
      filter.value.indexOf(",") > 0
    ) {
      newFilter.multipleValuesGTError = true;
    }
    if (
      filter.operator === "Less Than" &&
      filter.value.indexOf(",") > 0
    ) {
      newFilter.multipleValuesLTError = true;
    }
    return newFilter;
  });
  const formattedFilteredFilters = [...formattedFilters].filter(
    (_filter) => _filter.field !== "" || _filter.dbValue !== ""
  );
  const filtersHaveErrors =
    [...formattedFilters].filter(
      (filter) =>
        filter.dateFormatError ||
        filter.dateInvalidError ||
        filter.yearFormatError ||
        filter.yearInvalidError ||
        filter.invalidDateOperatorError ||
        filter.multipleValuesLTError ||
        filter.multipleValuesGTError ||
        (filter.operator === "" && filter.field !== "")
    ).length > 0;
  useEffect(() => {
    if (
      previousSelectionsCount > accountIdentificationSelections.length &&
      userAccountIdentification.accountIdentificationMethod ===
      "Account Number" &&
      (filteredFilters.length > 0 ||
        filters[0]?.field !== "" ||
        filters[0]?.operator !== "" ||
        filters[0]?.value !== "" ||
        filteredGroups.length > 0 ||
        groups[0]?.field !== "" ||
        groups[0]?.label !== "" ||
        largeLossValue !== "" ||
        dropDownElement !== "Incurred")
    ) {
      removeBlankFields();
      setFilters([BLANK_FILTER]);
      setGroups([BLANK_GROUP]);
      resetLargeLossFilter();
      setClearedCriteriaMsg(true);
    } else {
      setClearedCriteriaMsg(false);
    }
    console.log(clearedCriteriaMsg);
    setPreviousSelectionsCount(accountIdentificationSelections.length);
  }, [accountIdentificationSelections]);

  useEffect(() => {
    scrollToElement();
  }, [filtersHaveErrors]);
  const lossCriteria = {
    UserInformation: profileData[0],
  };



  let shouldSkip = (profileData === undefined || profileData === null || profileData?.length === 0) ||
    (profileData?.length > 0 && (
      profileData[0]?.Role.IsInternalUser ||
      (profileData[0]?.Role.IsAccountId === false && profileData[0]?.Role.IsAccountLocationId === false)
    ));

  const {
    data: prefill_data,
    isLoading,
  } = useGetPrefillDataQuery(lossCriteria, {
    skip: shouldSkip
  });

  useEffect(() => {
    if (
      prefill_data &&
      prefill_data.result &&
      prefill_data.result.Selections &&
      !isLoading
    ) {
      setAcctOrgLevelData(prefill_data.result);
    }
  }, [prefill_data, isLoading, profileData]);

  useEffect(() => {
    dispatch(showClaimActivityErrorMessage(false))
    dispatch(addDateRequiredErrorMessage(false))
    dispatch(addHistoryDateRequiredErrorMessage(false))
    dispatch(setSubmitClicked(false));
    if (location?.state?.account_id_selections) {
      setReportHeading(location?.state?.report_heading);
      setReportName(location?.state?.saved_params_action === "C" ? "Copy of " + location?.state?.report_name : location?.state?.report_name);
      setReportTypeSelected(location?.state?.report_type);
      setToDate(location?.state?.to_date);
      setFromDate(location?.state?.from_date);
      const hDt = getHistoryDateSavedParams(location?.state?.history_date);
      setHistoryDate(hDt !== undefined ? hDt : "");
      const hDt2 = getHistoryDateSavedParams(location?.state?.history_date_second);
      setHistoryDate2(hDt2 !== undefined ? hDt2 : "");
      const _filters = getFilters(location?.state?.filters);
      setFilters(_filters);
      setActiveFilter(_filters[0]);
      setFilteringAmount(location?.state?.claim_limit_amount === null ? "" : location?.state?.claim_limit_amount);
      setFilteringEndingAmount(location?.state?.claim_limit_end_amount === null ? "" : location?.state?.claim_limit_end_amount);
      setFilteringOperator(location?.state?.claim_limit_operator);
      setIsFilteringDirty(false);
      setFilteringOption(getLargeLossValue(location?.state?.claim_limit_type));
      setGroups(getGroups(location?.state?.groups));
      dispatch(updateAccountIdentification({
        ...userAccountIdentification,
        accountIdentificationMethod: location?.state?.account_id_method,
        accountIdentificationSelections: location?.state?.account_id_selections
      }))
      setParamId(location?.state?.param_id);
      setSavedParamsAction(location?.state?.saved_params_action);
    }
  }, [])

  function getFilters(filters: any[]) {
    let rtnFilters: FilterOption[] = [];
    if (filters) {
      filters.forEach((filter, index) => {
        rtnFilters.push({
          filterKey: index + 1,
          field: filter.DisplayName,
          operator: getOper(filter.Operator),
          value: filter.LimitValue,
          dbValue: filter.FieldName,
          isDirty: true,
          operatorError: false,
          dateInvalidError: false,
          dateFormatError: false,
          yearInvalidError: false,
          yearFormatError: false,
          invalidDateOperatorError: false,
          multipleValuesGTError: false,
          multipleValuesLTError: false,
        })
      })
    }
    return rtnFilters.length === 0 ? [BLANK_FILTER] : rtnFilters;
  }

  const reportData: ReportData = {
    LossesCriteria: {
      LargeLossValue: "",
      LargeLossType: 0,
      ClaimLimitDollarType: getLargeLossType(filteringOption),
      ClaimLimitOperator: filteringOperator,
      BeginDollarAmount: !filteringAmount ? null : Number(filteringAmount),
      EndDollarAmount: !filteringEndingAmount ? null : Number(filteringEndingAmount),
      Orgs: getOrgLevelData(),
      EffYearFrom: 0,
      EffYearTo: 0,
      RankFieldName: "",
      HistoryDate: getHistoryDate(historyDate),
      HistoryDateSecond: getHistoryDate(historyDate2),
      Limits:
        formattedFilteredFilters && formattedFilteredFilters.length > 0
          ? formattedFilteredFilters.map((_filter) => {
            return {
              FieldName: _filter.dbValue,
              DisplayName: _filter.field,
              Operator:
                getOperator(_filter.operator) === "-1"
                  ? "EQ"
                  : getOperator(_filter.operator),
              LimitValue: _filter.value,
              LimitType: 0,
            };
          })
          : [],
      ReportCategory: 0,
      AccountName: "",
      Selections: getSelections(),
      FromDate: fromDate,
      ToDate: toDate,
      Heading: reportHeading,
      OutputFormat: reportTypeSelected === "pdf" ? 0 : 1,
      ReportType: 14,
      AccountIdType: getAccountIdType(),
      SortGroups:
        filteredGroups && filteredGroups.length > 0
          ? filteredGroups
            .map((_group) => {
              const orgs = _group.field.split("ORG");
              const isOrg = orgs.length > 1 && orgs[1][0] !== "_";
              const lookupOrgs = [
                "Accident Result",
                "Adjusting Office",
                "Body Part",
                "Line of Insurance",
              ];
              const _lookupOrgs = [...dropDownData?.lookup?.map((org) => org._value) ?? [], ...lookupOrgs]
              return {
                FieldName: _group.field,
                Label: _group.label,
                Subtotal: _group.hasSubtotal,
                PageBreak: _group.hasPageBreak,
                LookupFieldText:
                  isOrg ||
                  (!orgLevelData || !orgLevelData.Headers
                    ? false
                    : orgLevelData.Headers.includes(_group.originalLabel)) ||
                  _lookupOrgs.includes(_group.field),
              };
            })
            .reverse()
          : [],
      BaseUrl: REACT_APP_ST_CLAIM_REPORT_URL,
      ShowDrilldown: true,
      ReportName: reportName,
      ParamId: 0,
      InsuredRetentionType: 0,
    },
    UserInformation:
      profileData && profileData.length ? profileData[0] : undefined,
  };

  const saveReportData = {
    ...reportData,
    ParameterCriteria: {
      ParamId: (paramId === undefined || savedParamsAction === "C") ? 0 : paramId,
      EventType: getEventType(),
      OverrideOrgCheck: false,
    },
  };

  function getEventType() {
    if (savedParamsAction === "C") {
      return 2;
    } else if (savedParamsAction === "M") {
      return 1;
    } else {
      return 0;
    }
  }

  const [saveData] = useUpdateParametersMutation();

  const scrollToElement = () => {
    if (filtersHaveErrors) ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToDateElement = () => {
    dateRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToFilteringElement = () => {
    filteringRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToTop = () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  const scrollToGroup = () => {
    if (groupByHasErrors) groupRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  function getOrgLevelData() {
    if (prefill_data && prefill_data.result && prefill_data.result.Orgs) {
      return prefill_data.result.Orgs;
    } else {
      return orgLevelData;
    }
  }
  function getSelections() {
    if (profileData && profileData.length > 0 && profileData[0].Role) {
      if (
        profileData[0].Role.IsAccountId === true ||
        profileData[0].Role.IsAccountLocationId === true
      ) {
        if (
          prefill_data &&
          prefill_data.result &&
          prefill_data.result.Selections
        ) {
          return prefill_data.result.Selections;
        }
      } else {
        return accountIdentificationSelections;
      }
    }
  }

  /**
  * Google Analytics Configuration based on User Type.
  * The IsAgent and IsBroker If any of the condition is true then it shoud be AGENT for GA4 Only.
  */
  function getUserType() {
    if (profileData && profileData.length > 0 && profileData[0].Role) {
      if (
        profileData[0].Role.IsAccountId === true ||
        profileData[0].Role.IsAccountLocationId === true
      ) {
        return "CUSTOMER";
      } else if (profileData[0].Role.IsInternalUser === true) {
        return "INTERNAL";
      } else if (profileData[0].Role.IsAgent === true || profileData[0].Role.IsBroker === true) {
        return "AGENT";
      } else {
        return ""
      }
    }
  }

  function getAccountIdType() {
    if (profileData && profileData.length > 0 && profileData[0].Role) {
      if (
        profileData[0].Role.IsAccountId === true ||
        profileData[0].Role.IsAccountLocationId === true
      ) {
        if (prefill_data && prefill_data.result)
          return prefill_data ? prefill_data.result.AccountIdType : "";
      } else {
        if (
          userAccountIdentification.accountIdentificationMethod ===
          "Policy Number"
        )
          return 2;
        else if (
          userAccountIdentification.accountIdentificationMethod ===
          "Account Number"
        )
          return 1;
        else if (
          userAccountIdentification.accountIdentificationMethod === "SAI Number"
        )
          return 4;
        else if (
          userAccountIdentification.accountIdentificationMethod ===
          "Producer Code"
        )
          return 3;
        else return -1;
      }
    }
  }


  const removeBlankFields = () => {
    if (filteredGroups.length === 0) {
      setGroups([BLANK_GROUP]);
    } else {
      setGroups(filteredGroups);
    }
    if (filteredFilters.length === 0) {
      setFilters([BLANK_FILTER]);
    } else {
      setFilters(filteredFilters);
    }
  };

  const saveForm = async () => {
    removeBlankFields();
    if (historyDate === "" || historyDate2 === "") {
      dispatch(addHistoryDateRequiredErrorMessage(true))
    }
    if (toDate === "" || fromDate === "") {
      dispatch(addDateRequiredErrorMessage(true));
      setDateHasErrors(true);
    }
    if (showFilteringError || showExtraFilteringError) {
      setIsFilteringDirty(true)
    }
    if (groupByHasErrors) {
      scrollToGroup()
      return true
    } else if ((profileData && profileData.length > 0 && profileData[0].Role &&
      (profileData[0].Role.IsAccountId !== true && profileData[0].Role.IsAccountLocationId !== true)) && accountIdentificationSelections.length === 0) {
      scrollToTop();
      dispatch(
        addAccountErrorMessage(
          "Please enter an account identifier to save the report criteria."
        )
      );
    } else if (claimActivityListError !== "") {
      scrollToTop();
      dispatch(showClaimActivityErrorMessage(true))
      return true;
    } else if (historyDate === historyDate2 || historyDate === "" || historyDate2 === "" || toDate === "" || fromDate === "" || checkDate2AfterDate1(historyDate, historyDate2)) {
      setDateHasErrors(true);
      scrollToDateElement();
      return true;
    } else if (showFilteringError || showExtraFilteringError) {
      setIsFilteringDirty(true)
      scrollToFilteringElement()
      return true;
    } else if (reportName.trim() === "") {
      setMissingReportName(true);
    } else {
      setMissingReportName(false);
      const { data }: any = await saveData(saveReportData);
      setSaveResponse(Number(data.result));
      dispatch(addSuccessMessage(""));
      if (data && data.result === "1") {
        dispatch(addSuccessMessage("success"));
      } else if (data && data.result === "2") {
        scrollToTop();
        dispatch(addSuccessMessage("updated"));
      }
    }
  };

  function checkDate2AfterDate1(hDate: string, hDate2: string) {
    if (hDate.substring(3, 7) > hDate2.substring(3, 7))
      return true;
    else if (hDate.substring(3, 7) === hDate2.substring(3, 7) && hDate.substring(0, 2) > hDate2.substring(0, 2))
      return true;
    else
      return false;
  }

  const submitForm = async () => {
    removeBlankFields();
    dispatch(showClaimActivityErrorMessage(true));

    /**
     * Google Analytics Configuration
     */
    const analyticsConfig = {
      /* ADD TOP LEVEL TEALIUM ATTRIBUTES HERE */
      action_value: `Submit Detail Changes Report click`,
      label_value: "SUBMIT",
      event_detail: `Detail Changes Report`,
      event_action: "report run",
      user_id: profileData[0]?.Id,
      login_id: profileData[0]?.Id,
      date: moment(new Date()).format('MM/DD/YYYY'),
      user_type: getUserType(),
      account_identification_type: userAccountIdentification.accountIdentificationMethod,
      capability: "Detail Changes",
      page_name: "DetailChanges",
      appid_cd: "loss-report",
      appcd: "loss-report"
    };

    const analyticsProps = getAnalyticsConfig("detailChangesReportSubmission");
    clickAnalytics({
      ...analyticsProps,
      ...analyticsConfig
    }, analytics);

    if (historyDate === "" || historyDate2 === "") {
      dispatch(addHistoryDateRequiredErrorMessage(true))
    }
    if (toDate === "" || fromDate === "") {
      dispatch(addDateRequiredErrorMessage(true));
      setDateHasErrors(true);
    }
    if (showFilteringError || showExtraFilteringError) {
      setIsFilteringDirty(true)
    }
    if (accountIdentificationSelections.length === 0) {
      scrollToTop();
      dispatch(
        addAccountErrorMessage(
          "Please enter an account identifier to run the report."
        )
      );
    }

    if (groupByHasErrors) {
      scrollToGroup()
      return true
    } else if (claimActivityListError !== "") {
      scrollToTop();
      dispatch(showClaimActivityErrorMessage(true))
      return true;
    } else if (historyDate === historyDate2 || historyDate === "" || historyDate2 === "" || toDate === "" || fromDate === "" || checkDate2AfterDate1(historyDate, historyDate2)) {
      setDateHasErrors(true);
      scrollToDateElement();
      return true;
    } else if (showFilteringError || showExtraFilteringError) {
      setIsFilteringDirty(true)
      scrollToFilteringElement()
      return true;
    } else {
      dispatch(addDateRequiredErrorMessage(false))
      //@ts-ignore
      const newFilters = [...formattedFilteredFilters]?.map((_filter) => {
        if (
          (_filter.field === "Accident Year" ||
            _filter.field === "Policy Year") &&
          !validYear(_filter.value)
        ) {
          _filter.yearFormatError = true;
          _filter.isDirty = true;
        } else if (_filter.yearFormatError) {
          _filter.yearFormatError = false;
        }
        if (
          (_filter.field === "Date of Hire (WC)" ||
            _filter.field === "Date of Loss" ||
            _filter.field === "Date Notice Recv'd" ||
            _filter.field === "Policy Eff Date") &&
          !isValidDate(_filter.value)
        ) {
          _filter.dateInvalidError = true;
          _filter.isDirty = true;
        } else {
          _filter.dateInvalidError = false;
        }

        if (
          (_filter.field === "Date of Hire (WC)" ||
            _filter.field === "Date of Loss" ||
            _filter.field === "Date Notice Recv'd" ||
            _filter.field === "Policy Eff Date") &&
          !validDateFormat(_filter.value)
        ) {
          _filter.dateFormatError = true;
          _filter.isDirty = true;
        } else {
          _filter.dateFormatError = false;
        }
        return _filter;
      });
      const _newFilters = formattedFilteredFilters.length === 0 ? [BLANK_FILTER] : formattedFilteredFilters
      setFilters(_newFilters);
      const hasErrors =
        newFilters.filter(
          (filter) =>
            filter.dateFormatError ||
            filter.dateInvalidError ||
            filter.yearFormatError ||
            filter.yearInvalidError ||
            filter.invalidDateOperatorError ||
            (filter.operator === "" && filter.field !== "")
        ).length > 0 || (accountIdentificationSelections.length === 0 && profileData[0]?.Role.IsAccountId === false && profileData[0]?.Role.IsAccountLocationId === false);
      return hasErrors;
    }
  };

  const resetForm = () => {
    removeBlankFields();
    dispatch(showClaimActivityErrorMessage(false))
    setHistoryDate("")
    setHistoryDate2("")
    setFilteringAmount("")
    setFilteringEndingAmount("")
    setFilteringOperator("")
    setFilteringOption("")
    resetLargeLossFilter();
    setFilters([BLANK_FILTER]);
    setGroups([BLANK_GROUP]);
    //reset dates
    setResetDates(false);
    // Used to get around the useEffect pattern in DateSelectionCriteria
    setTimeout(() => {
      setResetDates(true);
    }, 1);
    setDateHasErrors(false);
    //remove id's and reset to policy number
    dispatch(
      updateAccountIdentification({
        ...userAccountIdentification,
        ["accountIdentificationMethod"]: "Policy Number",
      })
    );
    if (savedParamsAction !== "M")
      setReportName("Detail Changes");
    dispatch(resetAgentAllSelectedAccounts());
    setMissingReportName(false);
    setSaveResponse(0);
    clearWarningMessages();
    dispatch(addSuccessMessage(""));
  };

  function resetLargeLossFilter() {
    setLargeLossValue("");
    setDropDownElement("Incurred");
  }

  function clearWarningMessages() {
    setClearMsg(true);
  }

  const showFilteringError = (filteringOption === "" || filteringOperator === "" || filteringAmount === "") && !(filteringOption === "" && filteringOperator === "" && filteringAmount === "") && !(filteringOption === null && filteringOperator === null && filteringAmount === null)
  const showExtraFilteringError = filteringOperator === "RG" && filteringEndingAmount === ""

  return (<div ref={topRef}>
    <PolicyNumber
      clearMsg={clearMsg}
      setClearMsg={setClearMsg}
      clearedCriteriaMsg={clearedCriteriaMsg}
      setClearedCriteriaMsg={setClearedCriteriaMsg}
      hasCustomFilters={
        userAccountIdentification.accountIdentificationMethod ===
        "Account Number" &&
        (filteredFilters.length > 0 ||
          filters[0]?.field !== "" ||
          filters[0]?.operator !== "" ||
          filters[0]?.value !== "" ||
          filteredGroups.length > 0 ||
          groups[0]?.field !== "" ||
          groups[0]?.label !== "" ||
          largeLossValue !== "" ||
          dropDownElement !== "Incurred")
      }
      presetAccountIdentificationOptions={["Policy Number", "Account Number", "SAI Number"]}
    />

    <AgentAccountSearch />
    <div ref={dateRef}>
      <DateSelectionCriteria
        hasDouble={true}
        disabledProducer={true}
        reset={resetDates}
        setReset={setResetDates}
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
        blankDates={true}
        historyDate={historyDate}
        setHistoryDate={setHistoryDate}
        historyDate2={historyDate2}
        setHistoryDate2={setHistoryDate2}
        setDateHasErrors={setDateHasErrors}
        dateHasErrors={dateHasErrors}
      />
    </div>
    <FormControl ref={ref}>
      <StackContainer spacing={6} title="Optional Report Criteria">
        <FilterSection
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          onShowModal={showModal}
          filters={formattedFilters}
          setFilters={setFilters}
          setOrgLevelData={setOrgLevelData}
          acctOrgLevelData={acctOrgLevelData}
          setAcctOrgLevelData={setAcctOrgLevelData}
          pageName="DetailChanges"
        />

        <Stack gap={0} ref={filteringRef}>
          <Text as="h4" textStyle="h4">
            Claim Activity Filtering
          </Text>
          <Text as="p" fontSize="sm" mt={-1}>
            Filter to claims with activity of:
          </Text>
          <Stack mt={-6} direction={['column', 'row', 'row']}>
            <FormControl width={200}>
              <TdsField label='Dollar Type'>
                <select
                  className='tds-field__input'
                  style={{
                    width: "95%",
                    height: "2rem",
                    verticalAlign: "top",
                    padding: "0 0 0 .75rem",
                    fontSize: "1rem",
                    textAlign: "left",
                  }}
                  value={filteringOption}
                  aria-label='dollar type'
                  name='tds-field__input'
                  onChange={(event) => {
                    setIsFilteringDirty(false)
                    setFilteringOption(event.target.value)
                  }}
                >
                  <option key="blank" defaultValue="" />
                  {Object.values(dollarTypeOptions).map((element, index) => (
                    <option key={index} value={element}>
                      {element}
                    </option>
                  ))}
                </select>
              </TdsField>
            </FormControl>
            <FormControl width={200}>
              <TdsField label='Operator'>
                <select className='tds-field__input'
                  aria-label='operator' name='tds-field__input'
                  style={{
                    width: "95%",
                    height: "2rem",
                    verticalAlign: "top",
                    padding: "0 0 0 .75rem",
                    fontSize: "1rem",
                    textAlign: "left",
                  }}
                  value={filteringOperator}
                  onChange={(event) => {
                    setIsFilteringDirty(false)
                    setFilteringOperator(event.target.value)
                  }
                  }
                >
                  <option key="blank" value="" />
                  {Object.values(dollarTypeOperatorOptions).map((element, index) => (
                    <option key={index} value={element.value}>
                      {element.label}
                    </option>
                  ))}
                </select>
              </TdsField>
            </FormControl>
            <FormControl width={200}>
              <FormLabel>$ Amount</FormLabel>
              <Input
                size="sm"
                type="text"
                inputMode="numeric"
                value={filteringAmount}
                onChange={(event) => {
                  if (!isNaN((+event.target.value))) {
                    setIsFilteringDirty(false)
                    setFilteringAmount(String(event.target.value).replace("-", "").replace("+", ""))
                  }
                }}
              />
            </FormControl>
            {filteringOperator === "RG" &&
              <FormControl ml={3} width={200}>
                <FormLabel>Ending $ Amount</FormLabel>
                <Input
                  size="sm"
                  type="text"
                  inputMode="numeric"
                  value={filteringEndingAmount}
                  onChange={(event) => {

                    if (!isNaN((+event.target.value))) {
                      setIsFilteringDirty(false)
                      setFilteringEndingAmount(String(event.target.value).replace("-", "").replace("+", ""))
                    }
                  }}
                />
              </FormControl>
            }
          </Stack>
          <div
            data-testid="date_error"
            className="tds-field--error"
            style={{ marginBottom: 0, marginTop: -20 }}
          >
            <div
              data-testid="invalid_date"
              id="date-selection-invalid-from-date"
              aria-atomic="true"
              className="tds-field__message"
              role="alert"
              hidden={!(isFilteringDirty && showFilteringError)}
            >
              <p style={{ fontSize: "14px" }}>
                Dollar Type, Operator and $ Amount required for Claim Activity</p>
            </div>

            <div
              data-testid="invalid_date"
              id="date-selection-invalid-from-date"
              aria-atomic="true"
              className="tds-field__message"
              role="alert"
              hidden={!isFilteringDirty || !showExtraFilteringError}
            >
              <p style={{ fontSize: "14px" }}>Ending dollar amount required if Range is selected operator</p>
            </div>
          </div>
        </Stack>
        <div ref={groupRef}>
          <GroupBySection
            groups={groups}
            setGroups={setGroups}
            setOrgLevelData={setOrgLevelData}
            acctOrgLevelData={acctOrgLevelData}
            setAcctOrgLevelData={setAcctOrgLevelData}
            setGroupByHasErrors={setGroupByHasErrors}
            pageName={"DetailChanges"}
          />
        </div>
      </StackContainer>
    </FormControl>
    <SubmissionSection
      onResetForm={resetForm}
      onSaveForm={saveForm}
      onSubmitForm={submitForm}
      reportName={reportName}
      setReportName={setReportName}
      reportHeading={reportHeading}
      setReportHeading={setReportHeading}
      reportTypeSelected={reportTypeSelected}
      setReportTypeSelected={setReportTypeSelected}
      missingReportNameError={missingReportName}
      setMissingReportName={setMissingReportName}
      reportData={reportData}
      saveResponse={saveResponse}
      setSaveResponse={setSaveResponse}
      disableButtons={dateHasErrors || filtersHaveErrors || (claimActivityListError !== "" && showClaimActivityError)}
      formHasErrors={filtersHaveErrors}
      paramId={savedParamsAction === "M" ? paramId : 0}
    />
    {showReportFilteringResultsModal &&
      chosenFilter.field !== "Accident Cause" && (
        <ReportFilteringResults
          finalFocusRef={ref}
          setShowReportFilteringResultsModal={
            setShowReportFilteringResultsModal
          }
          isOpen={showReportFilteringResultsModal}
          setIsOpen={setShowReportFilteringResultsModal}
          setFilters={setFilters}
          filters={filters}
          filter={activeFilter}
        />
      )}
    {showReportFilteringResultsModal &&
      chosenFilter.field === "Accident Cause" && (
        <WCReportFilteringResults
          finalFocusRef={ref}
          setShowReportFilteringResultsModal={
            setShowReportFilteringResultsModal
          }
          isOpen={showReportFilteringResultsModal}
          setIsOpen={setShowReportFilteringResultsModal}
          setFilters={setFilters}
          filters={filters}
          filter={activeFilter}
        />
      )}
  </div>
  );
};

export default DetailChanges;
