import { FormControl, FormLabel, Switch } from '@chakra-ui/react';
import { TdsGlobalHeader } from '@trv-tds/react';
import useLocalStorage from '../../hooks/useLocalStorage';

const Header = () => {
  const [isDefaultUser, setIsDefaultUser] = useLocalStorage<boolean>("isDefaultUser", false);
  function toggleLocalUser(event: React.ChangeEvent<HTMLInputElement>): void {
    event.preventDefault()
    setIsDefaultUser(!isDefaultUser)
  }

  return (
    <TdsGlobalHeader homelabel='Loss Reports'>
      <aside slot='product' aria-label='loss_reports_application_header'>
        Loss Reports
      </aside>
      {window.location.href.includes('localhost:') && (
        <div slot='actions' aria-label='header-form'>
          <FormControl display='flex' alignItems='center' aria-label='header-formcontrol'>
            <FormLabel htmlFor='email-alerts' mb='0' aria-label='header-formlabel'>
              Enable default user
            </FormLabel>
            <Switch isChecked={isDefaultUser} 
            onChange={toggleLocalUser}
            
            id='email-alerts' />
          </FormControl>
        </div >
      )}
    </TdsGlobalHeader>
  );
};
export default Header;
