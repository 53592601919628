import { Analytics_Tealium as AnalyticsTealium } from '@trv/bi-digital-web-analytics';

export type AnalyticProps = {
  action_value: string | "",
  label_value: string | "",
  event_detail: string | "",
  event_action: string | "",
  page_name?: string,
  user_id?: string,
  login_id?: string
  date?: string,
  user_type?: string,
  account_identification_type?: string,
  capability?: string,
  appid_cd?: string,
  appcd?: string
}

export const clickAnalytics = (props: AnalyticProps, analytic: any) => {
  analytic.track("click", {
    action_value: props.action_value,
    label_value:props.label_value,
    event_detail: props.event_detail,
    event_action: props.event_action,
    user_id: props.user_id,
    login_id: props.login_id,
    date: props.date,
    user_type: props.user_type,
    account_identification_type: props.account_identification_type,
    capability: props.capability,
    page_name: props.page_name,
    appid_cd: "loss-report",
    appcd: "loss-report"
  });
}

export const setupAnalytics = (props: AnalyticProps) => {
  const analyticConfig = {
    action_value: props.action_value,
    label_value:props.label_value,
    event_detail: props.event_detail,
    event_action: props.event_action,
    user_id: props.user_id,
    login_id: props.login_id,
    date: props.date,
    user_type: props.user_type,
    account_identification_type: props.account_identification_type,
    capability: props.capability,
    page_name: props.page_name,
    appid_cd: "loss-report",
    appcd: "loss-report"
  };
  if (window.utag && window.utag.view) {
    window.utag.view(analyticConfig);
  }
  const initAnalytics = AnalyticsTealium && new AnalyticsTealium(analyticConfig);
  return initAnalytics;
}
  