import { apiSlice } from "../../app/api/apiSlice";
import { API, REQ_TYPE } from "../../common/constants";
import { APIResult, AccountSearchRequest, AuthenticatedQuery, DeleteSAIMutation, SAIAlertData, SaveSAIMutation } from "../../common/types";

export const emailAlertApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({

    getSAIAlerts: builder.query<SAIAlertData, AuthenticatedQuery>({
      query: (arg) => {
        return {
          method: REQ_TYPE.POST,
          url: API.GET_ALL_SAI_ALERTS,
          body: arg,
        };
      },
      //@ts-ignore
      providesTags: [{ type: "Subscribe" } as const],
      transformResponse: (response: APIResult<SAIAlertData>) => response.result,
    }),

    saveSAIAlert: builder.mutation<APIResult<boolean>, SaveSAIMutation>({
      query: (arg) => {
        return {
          method: REQ_TYPE.POST,
          url: API.SAVE_SAI_ALERT,
          body: arg,
        };
      },
      // transformResponse: (response: APIResult<boolean>) => response,
      // @ts-ignore
      invalidatesTags: [{ type: "Subscribe" } as const],
    }),

    deleteSAIAlert: builder.mutation<DeleteSAIMutation, any>({
      query: (arg) => {
        return {
          method: REQ_TYPE.POST,
          url: API.DELETE_SAI_ALERT,
          body: arg,
        };
      },
      transformResponse: (response: any) => response,
      // @ts-ignore
      invalidatesTags: [{ type: "Subscribe" } as const],
    }),

    getEmailSubscriptions: builder.query<any, AuthenticatedQuery>({
      query: (arg) => {
        return {
          method: REQ_TYPE.POST,
          url: API.GET_EMAIL_SUBSCRIPTIONS,
          body: arg,
        };
      },
      //@ts-ignore
      providesTags: [{ type: "Subscribe" } as const],
      transformResponse: (response: any) => response,
    }),
    getClearingHouseIds: builder.query<any, AuthenticatedQuery>({
      query: (arg) => {
        return {
          method: REQ_TYPE.POST,
          url: API.GET_CLEARINGHOUE_IDS,
          body: arg,
        };
      },
      //@ts-ignore
      providesTags: [{ type: "Subscribe" } as const],
      transformResponse: (response: any) => response,
    }),
    subscribeEmailAlert: builder.mutation<AccountSearchRequest, any>({
      query: (arg) => {
        return {
          method: REQ_TYPE.POST,
          url: API.SUBSCRIBE_EMAIL_ALERT,
          body: arg,
        };
      },
      transformResponse: (response: any) => response,
      // @ts-ignore
      invalidatesTags: [{ type: "Subscribe" } as const],
    }),
    unsubscribeEmailAlert: builder.mutation<AccountSearchRequest, any>({
      query: (arg) => {
        return {
          method: REQ_TYPE.POST,
          url: API.UNSUBSCRIBE_EMAIL_ALERT,
          body: arg,
        };
      },
      transformResponse: (response: any) => response,
      // @ts-ignore
      invalidatesTags: [{ type: "Subscribe" } as const],
    }),
  }),
});

export const {
  useGetSAIAlertsQuery,
  useSaveSAIAlertMutation,
  useDeleteSAIAlertMutation,
  useGetEmailSubscriptionsQuery,
  useGetClearingHouseIdsQuery,
  useSubscribeEmailAlertMutation,
  useUnsubscribeEmailAlertMutation } = emailAlertApiSlice;
