import { Button, Checkbox, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';
import { ReactComponent as ClaimActivityEmailMaintenanceCloseIcon } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/x-1-12.svg';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { EmailingInternal } from '../../../common/types';
import { useDeleteSAIAlertMutation, useGetSAIAlertsQuery } from '../../../features/emailAlerts/emailAlertApiSlice';
import useAuth from '../../../hooks/useAuth';
import SAISelectBox from '../AccountIdentification/SAISelectBox';
import { Loader } from '../Common/Loader';

type ClaimActivityEmailMaintenanceProps = {
  setShowClaimActivityEmailMaintenanceDialog: Dispatch<SetStateAction<boolean>>;
};
interface SAIAlert extends EmailingInternal {
  selected: boolean;
}
const ClaimActivityEmailMaintenance = ({ setShowClaimActivityEmailMaintenanceDialog }: ClaimActivityEmailMaintenanceProps) => {
  let renderClaimActivityEmailMaintenanceTableData: any = null;
  const [SAIAlerts, setSAIAlerts] = useState<SAIAlert[]>()
  const [isDeleting, setIsDeleting] = useState(false)
  const { profileData } = useAuth();

  const queryData = {
    UserInformation: profileData[0],
  };

  const { data, isLoading, isFetching } = useGetSAIAlertsQuery(queryData)
  const [deleteSAIAlert] = useDeleteSAIAlertMutation()
  const _saiAlerts = data?.LR_EMAILING_INTERNAL?.map((_data) => {
    return { ..._data, selected: false }
  })

  const allSelectedSAIAlerts = SAIAlerts?.filter(alert => alert.selected)
  const allAlertsChecked = allSelectedSAIAlerts && allSelectedSAIAlerts?.length > 0 && allSelectedSAIAlerts?.length === _saiAlerts?.length

  useEffect(() => {
    if (JSON.stringify(SAIAlerts?.map(({ selected, ...rest }) => rest)) !== JSON.stringify(_saiAlerts?.map(({ selected, ...rest }) => rest))) {
      setSAIAlerts(_saiAlerts)
    }
  }, [_saiAlerts, SAIAlerts])


  // const allSelected = 
  const renderClaimActivityEmailMaintenanceTableHeader = [
    <Thead
      mt={-4}
      pt={4}
      position="sticky"
      top={0}
      backgroundColor="white"
      boxShadow="inset 0 -2px 0 #000000"
      zIndex={1}
      width="100%"
    >
      <Tr>
        <Th width={50}>
          <Checkbox
            aria-label="select SAI alertf"
            onChange={(e) => {
              if (allAlertsChecked) {
                setSAIAlerts(SAIAlerts?.map(alert => {
                  return { ...alert, selected: false }
                }))

              } else {
                setSAIAlerts(SAIAlerts?.map(alert => {
                  return { ...alert, selected: true }
                }))
              }
            }}
            isChecked={allAlertsChecked}
          />
        </Th>
        <Th>
          ACCOUNT NAME
        </Th>
        <Th>
          SAI Number(s)
        </Th>
      </Tr>
    </Thead>,
  ];


  if (SAIAlerts) {
    renderClaimActivityEmailMaintenanceTableData = SAIAlerts.map((record, index) => {
      const { SEQ_NBR_ID: Id, ACCT_NM_TXT: AccountName, SAI_NOS: SAINumber, selected } = record
      return (
        <Tr key={Id}>
          <Td border="solid" borderWidth={1} borderColor="gray.100">
            <Checkbox
              key={AccountName}
              aria-label="select SAI alertf"
              onChange={(e) => {
                const newAlerts = [...SAIAlerts?.map(alert => {
                  return { ...alert, selected: alert.SEQ_NBR_ID === Id ? !selected : alert.selected }
                })]
                setSAIAlerts(newAlerts)
              }}
              isChecked={selected}
            />
          </Td>
          <Td border="solid" borderWidth={1} borderColor="gray.100">
            {AccountName}
          </Td>
          <Td border="solid" borderWidth={1} borderColor="gray.100">
            {SAINumber}
          </Td>
        </Tr >
      );
    });
  }

  return (
    <div role='dialog' className='tds-modal tds-modal__dialog--large' aria-label='claim activity email maintenance dialog'>
      <div role='document' className='tds-modal__dialog-container claim-activity-email-maintenance-dialog-container'>
        <div className='tds-modal__dialog-header'>
          <h2 className='tds-modal__dialog-title' style={{ color: '#46494d' }}>
            Claim Activity Email Maintenance
          </h2>
        </div>

        <div
          className='tds-modal__dialog-body'>
          <div style={{ borderBottom: '1px solid #dadbdb', marginTop: '1rem', marginBottom: '1rem' }}>
            <SAISelectBox />
          </div>
          {(isLoading || isFetching) && (<Loader p={50} />)}
          {SAIAlerts && SAIAlerts?.length > 0 && (
            <div>
              <Text as="h4" mx={2} my={4}>
                My Saved Alerts
              </Text>

              <Table
                size="sm"
                variant='unstyled'
                layout="fixed"
              >
                {renderClaimActivityEmailMaintenanceTableHeader}
                <Tbody>{renderClaimActivityEmailMaintenanceTableData}</Tbody>
              </Table>
              <div className='sai-delete-sel-btn-div'>
                <Button
                  isLoading={isDeleting || isFetching || isLoading}
                  onClick={async () => {
                    setIsDeleting(true)
                    const deleteSelections = [...SAIAlerts.filter(alert => alert.selected).map(alert => alert.SEQ_NBR_ID)]
                    await deleteSAIAlert({ LossesCriteria: { Selections: deleteSelections } })
                    setIsDeleting(false)
                  }}
                  isDisabled={!allSelectedSAIAlerts || allSelectedSAIAlerts?.length === 0}
                  variant="outline"
                >
                  DELETE SELECTED
                </Button>
              </div>
            </div>
          )}

          <div className='sai-close-btn-div'>
            <button onClick={() => setShowClaimActivityEmailMaintenanceDialog(false)} className='tds-button--primary tds-button--small sai-close-btn' type='button'>
              CLOSE
            </button>
          </div>

          <button onClick={() => setShowClaimActivityEmailMaintenanceDialog(false)} className='tds-modal__dismiss' aria-label='Dismiss Claim Activity e-Alerts' type='button'>
            <ClaimActivityEmailMaintenanceCloseIcon alt='Close Alert' />
          </button>
        </div>
      </div>
    </div>
  );
};
export default ClaimActivityEmailMaintenance;
