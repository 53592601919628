import { useNavigate } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import OktaConfig from './components/auth/oktaConfig';
import useAuth from './hooks/useAuth';
import { useLocation } from 'react-router-dom';
import { AnalyticsProvider, Analytics_Tealium as AnalyticsTealium } from '@trv/bi-digital-web-analytics';
import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { AppContext } from './context/appContext';

// const RMISOktaAuthOptions = {
//   issuer: `https://${process.env.REACT_APP_OKTA_DOMAIN}/oauth2/aus1vxtwa89Wh7Kcz0h8`,

//   //issuer: `https://${process.env.REACT_APP_OKTA_DOMAIN}/oauth2/aus1wl833sqqiA1Yg0h8`,//rmis loss reports auth server
//   //issuer: `https://${process.env.REACT_APP_OKTA_DOMAIN}/oauth2/aus16frbv1j0oH99s0h8`,//original auth server - bond and SI shared auth server
//   clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
//   //redirectUri: 'https://bi-rmis-lrptui-uat.thc.travp.net',
//   redirectUri: window.location.origin + '/index.html', // Should work for both local and remote.
//   // redirectUri:'http://localhost:3000/login/callback' ,	// For local testing.
//   pkce: true,
// };

// const oktaAuth = new OktaAuth(RMISOktaAuthOptions);

type SecurityWrapperProps = {
  children: React.ReactNode;
};
function SecurityWrapper({ children }: SecurityWrapperProps) {

  const oktaAuth = new OktaAuth(OktaConfig());
  // console.log('oktaAuth::', oktaAuth);
  const navigate = useNavigate();
  const { roleCodes, isAgentUser, isInternalUser, isAccountLocationUser, isAccountId, profileData } = useAuth();
  const location = useLocation();
  const { search } = location;

  if (search === '?page=NationalPrograms' && !hasNatProgramsRoleCode()) {
    navigate('./');
  }

  const restoreOriginalUri = async (oktaAuth: unknown, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };
  function hasNatProgramsRoleCode() {
    const hasRoleCode = roleCodes.filter((element) => {
      return element.toUpperCase() === 'ELRS1' || element.toUpperCase() === 'NEPVBILOSS' || element.toUpperCase() === 'NLOSSONLY';
    })
    return hasRoleCode.length > 0;
  }

  const triggerLogin = async () => {
    await oktaAuth.signInWithRedirect();
  };



  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // Trigger OKTA authentication
      await triggerLogin();
    }
  };

  /**
  * Google Analytics Configuration based on User Type.
  * The IsAgent and IsBroker If any of the condition is true then it shoud be AGENT for GA4 Only.
  */
  function getUserType() {
    if (profileData && profileData.length > 0 && profileData[0].Role) {
      if (
        profileData[0].Role.IsAccountId === true ||
        profileData[0].Role.IsAccountLocationId === true
      ) {
        return "CUSTOMER";
      } else if (profileData[0].Role.IsInternalUser === true) {
        return "INTERNAL";
      } else if (profileData[0].Role.IsAgent === true || profileData[0].Role.IsBroker === true) {
        return "AGENT";
      } else {
        return ""
      }
    }
  }

  const [analytics, setAnalytics] = useState(AnalyticsTealium && new AnalyticsTealium({ appcd: 'loss-report' }));

  const initializeAnalytics = () => {
    setAnalytics(() => {
      const analyticsConfig = {
        /* ADD TOP LEVEL TEALIUM ATTRIBUTES HERE */
        action_value: "Load Loss Report Application",
        label_value: "LOAD",
        event_detail: "",
        event_action: "report run",
        user_id: profileData[0]?.Id,
        login_id: profileData[0]?.Id,
        date: moment(new Date()).format('MM/DD/YYYY'),
        user_type: getUserType(),
        account_identification_type: "",
        capability: "",
        page_name: "Loss Report Application",
        appid_cd: "loss-report",
        appcd: "loss-report"
      };
      // utag.view(user_id, page_name, user_type)
      // const utagWindow = (window as any).utag;
      // if (utagWindow && utagWindow.view) {
      //   utagWindow.view(analyticsConfig);
      // }
      const initAnalytics = AnalyticsTealium && new AnalyticsTealium(analyticsConfig);
      return initAnalytics;
    })
  };

  useEffect(() => {
    initializeAnalytics()
  }, [])

  return (
    <AnalyticsProvider analytics={analytics}>
      <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler} restoreOriginalUri={restoreOriginalUri}>
        {children}
      </Security>
    </AnalyticsProvider>
  );
}

export default SecurityWrapper;
