import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { AccountTypes } from "../../features/auth/auth";
import { UserProfile } from "../../features/profile/profile";
import { useGetProfileQuery } from "../../features/profile/profileApiSlice";
import SideNavigation from "./SideNavigation";
import { addAccountType, addAllProfileData, addRoleCode } from "./profileSlice";

const Dashboard = () => {
  const dispatch = useDispatch();
  const {
    data: profileData,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetProfileQuery();

  useEffect(() => {
    if (!isLoading && isSuccess && profileData) {
      const userProfile: UserProfile = profileData;
      const {
        IsBroker,
        IsLossReportsUserNationalPrograms,
        IsAccountLocationId,
        IsInternalUser,
        IsTracerUser,
        RoleCodes,
        IsAccountId,
      } = userProfile?.Role ?? {};
      dispatch(addAllProfileData(profileData));
      if (IsBroker) {
        dispatch(addAccountType(AccountTypes.Agent));
      }
      if (IsLossReportsUserNationalPrograms) {
        dispatch(addAccountType(AccountTypes.NationalAccount));
      }
      if (IsAccountLocationId) {
        dispatch(addAccountType(AccountTypes.LocAccount));
      }
      if (IsInternalUser) {
        dispatch(addAccountType(AccountTypes.InternalUser));
      }
      if (IsTracerUser) {
        dispatch(addAccountType(AccountTypes.TracerUser));
      }
      if (IsAccountId) {
        dispatch(addAccountType(AccountTypes.AccountUser));
      }
      dispatch(addRoleCode(RoleCodes));
    }
  }, [profileData]);
  return (
    <div
      className="tds-body dashboard-main-div"
      id="maincontent"
      aria-label="loss reports dashboard"
    >
      <SideNavigation />
      <Outlet />
    </div>
  );
};

export default Dashboard;
