import { useEffect, useState } from "react";
import Header from "../components/Header/TDSGlobalHeader";
import TopHat from "../components/Header/TDSTopHat";
import WelcomeBanner from "../components/Header/WelcomeBanner";
import Dashboard from "../components/Shared/Dashboard";

import { AccessToken, AuthState, IDToken } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { TdsGlobalFooter, TdsGlobalFooterLinks } from "@trv-tds/react";
import { useDispatch } from "react-redux";
import {
  AuthState as UserAuthState,
  setCredentials,
} from "../features/auth/authSlice";

const HomeContainer = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userName, setUserName] = useState<string | null>(null);
  const [userPermissions, setUserPermissions] = useState(null);
  const dispatch = useDispatch();
  let isAgent = false;
  const { isAuthenticated, accessToken, idToken } = authState as AuthState;
  const { accessToken: userAccessToken } = accessToken as AccessToken;
  const { claims } = idToken as IDToken;
  useEffect(() => {
    if (isAuthenticated) {
      const userName = idToken?.claims.name
        ? idToken.claims.name.split(" ")[0]
        : null;
      setUserName(userName);
      let authData: UserAuthState = {
        user: claims,
        token: userAccessToken,
      };
      dispatch(setCredentials(authData));
      console.log("accessToken: " + userAccessToken);
    } else {
      setUserName(null);
    }
  }, [authState, oktaAuth, accessToken, userPermissions]); // Update if authState changes

  return (
    <div className="tds-app-wrapper" aria-label="home-container-app-wrapper">
      <div
        className="app-main-class"
        aria-label="home-container-app-main-class"
      >
          <TopHat aria-label="home-container-tophat" ></TopHat>
          <Header aria-label="home-container-header" aria-level={1}></Header>
          <WelcomeBanner userName={userName} aria-label="home-container-welcome-banner"></WelcomeBanner>
          <Dashboard aria-label="home-container-dashboard" ></Dashboard>
      </div>
      <TdsGlobalFooter id="footer" aria-label="home-container-footer">
        <TdsGlobalFooterLinks slot="links">
          <a
            target="_blank"
            href="https://www.travelers.com/about-travelers/privacy-statements "
          >
            Privacy
          </a>
          <a
            target="_blank"
            href="https://www.travelers.com/about-travelers/legal"
          >
            Legal Notices
          </a>
        </TdsGlobalFooterLinks>
      </TdsGlobalFooter>
    </div>
  );
};

export default HomeContainer;
