import {
  BoxProps,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Stack
} from '@chakra-ui/react';
import { TdsField, TdsLoadingSpinner } from '@trv-tds/react';
import { saveAs } from 'file-saver';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { NationalProgramsReportData, ReportData } from '../../../common/types';
import { addSuccessMessage, setSubmitClicked } from '../../../features/claimActivity/claimActivityErrorMsgSlice';
import { useSubmitParametersMutation } from '../../../features/submitReport/submitReportApiSlice';
import { StackContainer } from "../../Layout/StackContainer";
interface SubmissionSectionProps extends BoxProps {
  onSubmitForm: Function,
  onSaveForm: Function,
  onResetForm: Function,
  reportName?: string,
  setReportName: Function,
  reportHeading?: string,
  setReportHeading: Function,
  reportTypeSelected: string,
  setReportTypeSelected: Function,
  missingReportNameError: boolean,
  setMissingReportName?: Function,
  saveResponse: number,
  setSaveResponse?: Function,
  reportData?: ReportData | NationalProgramsReportData,
  disableButtons?: boolean,
  formHasErrors?: boolean,
  pageName?: string,
  displayOrSummarize?: string,
  setDisplayOrSummarize?: Function,
  paramId?: number,
}
export const SubmissionSection = (props: SubmissionSectionProps) => {
  const { onSubmitForm, onSaveForm, onResetForm, reportName, setReportName, reportHeading, setReportHeading, reportTypeSelected, setReportTypeSelected, missingReportNameError, setMissingReportName, setSaveResponse, saveResponse, reportData, disableButtons = false, formHasErrors, pageName, displayOrSummarize, setDisplayOrSummarize, paramId } = props;


  const [postReportData] = useSubmitParametersMutation()
  const [saveLoading, setSaveLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const dispatch = useDispatch();

  function reset() {
    dispatch(addSuccessMessage(""));
    setReportHeading('');
    setReportTypeSelected(getDefaultValue());
    onResetForm();
  }

  async function openXLSX() {
    dispatch(setSubmitClicked(true))
    const hasErrors = await onSubmitForm();
    if (!formHasErrors && !hasErrors) {
      setSubmitLoading(true)
      dispatch(addSuccessMessage(""));

      if (reportData) {
        const { data }: any = await postReportData(reportData);
        const moreThanSixMb: number = Number(data?.messages[0].code) || 0;
        if (data && data.result) {
          if (moreThanSixMb == 1) {
            const linkSource = data.result;
            const fileName = `report.xlsx`;
            saveAs(linkSource, fileName);
          } else {
            const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data.result}`;
            const fileName = `report.xlsx`;
            saveAs(linkSource, fileName);
          }
        }
      }
      setSubmitLoading(false)
    }
  }


  async function openPDF() {
    dispatch(setSubmitClicked(true))
    const hasErrors = await onSubmitForm();
    if (!formHasErrors && !hasErrors) {
      setSubmitLoading(true)
      dispatch(addSuccessMessage(""));
      if (reportData) {
        const { data }: any = await postReportData(reportData);
        const moreThanSixMb: number = Number(data?.messages[0].code) || 0;
        const windowParams = `left=200, top=200, toolbar=0, status=0, width=${screen.width + (-200)}, height=${window.innerHeight}`;
        if (data && data.result) {
          if (moreThanSixMb == 1) {
            console.log('Report file size is more than 6 MB.', data.result);
            const pdfURL: string = data.result;
            window.open(pdfURL, 'primary.pdf', windowParams);
          } else {
            const blob = base64toBlob(`data:application/pdf;base64, ${data.result}`);
            const fileURL = window.URL.createObjectURL(blob);
            console.log('URL ::', fileURL.substring(5));
            // window.open(fileURL, 'sharer', `toolbar=0,status=0,width=${screen.width + (-200)},height=${window.innerHeight + (-100)}`);
            window.open(fileURL, 'primary', windowParams);
          }
        }
      }
      setSubmitLoading(false)
    }
  }

  function setRadioValue(e: any) {
    dispatch(addSuccessMessage(""));
    setReportTypeSelected(e);
  }
  function setDisplaySummarizeValue(e: any) {
    dispatch(addSuccessMessage(""));
    if (setDisplayOrSummarize)
      setDisplayOrSummarize(e);
  }

  function getDefaultValue() {
    if (pageName === "InsuredRetention") {
      return 'excel';
    } else
      return 'pdf';
  }
  const base64toBlob = (data: string) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);
    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: 'application/pdf' });
  };

  return (<StackContainer stackProps={{ justifyContent: 'space-around', width: '100%' }} width="100%" title="Report Submission">
    <FormControl width="40%">
      <TdsField label='Report Heading' >
        <Input type='text' borderColor='gray' placeholder='' maxLength={62} onChange={(e) => {
          dispatch(addSuccessMessage(""));
          setReportHeading(e.target.value)
        }
        } value={reportHeading} />
      </TdsField>
    </FormControl>
    <FormControl width="40%">
      <TdsField label='Report Name' data-testid='value-id' alert={(missingReportNameError || (saveResponse === 5)) ? 'error' : 'false'}>
        <Input type='text' borderColor='gray' maxLength={62} disabled={paramId !== undefined && paramId !== 0} onChange={(e) => {
          dispatch(addSuccessMessage(""));
          setReportName(e.target.value)
          if (setSaveResponse)
            setSaveResponse(0)
          if (setMissingReportName)
            setMissingReportName(false)
        }
        } value={reportName} />
        <div className='tds-field--error' style={{ marginBottom: 0 }} >
          <div id="detail-loss-missing-report-name-error" aria-atomic='true' className='tds-field__message' role='alert'
            hidden={!missingReportNameError}>
            <p style={{ fontSize: '14px' }} >
              Please enter Report Name to save the parameters.
            </p>
          </div>
          <div id="detail-loss-non-unique-report-name-error" aria-atomic='true' className='tds-field__message' role='alert'
            hidden={!(saveResponse === 5)}>
            <p style={{ fontSize: '14px' }} >
              Report name already exists.
            </p>
          </div>
        </div>
      </TdsField>
    </FormControl>
    {pageName === 'NationalPrograms' && (
      <><FormControl>
        <RadioGroup defaultValue={'display'} value={displayOrSummarize} onChange={(e) => {
          setDisplaySummarizeValue(e);
        }}>

          <FormLabel>Multiple Producer/Program Codes Selected:</FormLabel>
          <Stack width="100%">
            <Radio borderRadius="400px" borderColor="gray.500" value='display'>
              Display Each Producer/Program Code/Name
            </Radio>
            <Radio value='summarize'>
              Summarize Info For All Producer/Program Codes
            </Radio>
          </Stack>
        </RadioGroup>
      </FormControl><div></div></>
    )}
    <FormControl>
      <FormLabel textStyle="labelSpecial" fontSize="xs" fontWeight={'bold'}>OUTPUT FORMAT</FormLabel>
      <RadioGroup defaultValue={getDefaultValue()} value={reportTypeSelected} onChange={(e) => {
        setRadioValue(e);
      }}>
        <Stack width="20%">
          <Radio borderRadius="400px" borderColor="gray.500" value='excel'>
            Microsoft Excel
          </Radio>
          {pageName !== "InsuredRetention" &&
            <Radio value='pdf'>PDF</Radio>}
        </Stack>
      </RadioGroup>
    </FormControl>
    {submitLoading && <TdsLoadingSpinner label="Processing" size="xxx-large" label-displayed overlay centered></TdsLoadingSpinner>}
    <Flex>
      <Button
        isDisabled={disableButtons}
        isLoading={submitLoading}
        data-testid="submit-button"
        mr={4} onClick={() => reportTypeSelected === 'pdf' ? openPDF() : openXLSX()}
        size='lg'
        variant='solid'
        color={"white"}
      >
        Submit
      </Button>
      <Button
        isLoading={saveLoading}
        isDisabled={disableButtons}
        data-testid="save-button"
        mr={4} onClick={async () => {
          setSaveLoading(true)
          await onSaveForm()
          dispatch(setSubmitClicked(true))
          setSaveLoading(false)
        }}
        size='lg'
        variant='outline'
      >
        Save
      </Button>
      <Button onClick={() => reset()} size='lg' variant='capsLink'>
        Reset
      </Button>
    </Flex>
  </StackContainer>
  );
};

