import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface ClaimActivityErrorMessageState {
  claimActivityErrorMessage: string
  accountErrorMessage: string
  successMessage: string
  showClaimActivityError: boolean
  wasNationalProgramsClicked: string
  submitClicked: boolean
  savedParametersErrorMessage: string
  savedParametersDuplicateMessage: string
  savedParametersEmptyMessage: string
  savedParametersSuccessfulRenameMessage: string
  searchResultsClicked: boolean
}

const initialState: ClaimActivityErrorMessageState = {
  claimActivityErrorMessage: "",
  accountErrorMessage: "",
  successMessage: "",
  showClaimActivityError: false,
  wasNationalProgramsClicked: "",
  submitClicked: false,
  savedParametersErrorMessage: "",
  savedParametersDuplicateMessage: "",
  savedParametersEmptyMessage: "",
  savedParametersSuccessfulRenameMessage: "",
  searchResultsClicked: false
}


export const claimActivityErrorMsgSlice = createSlice({
  name: 'claimActivityErrorMessage',
  initialState,
  reducers: {
    showClaimActivityErrorMessage: (state: ClaimActivityErrorMessageState, action: PayloadAction<boolean>) => {
      state.showClaimActivityError = action.payload;
    },
    addErrorMessage: (state: ClaimActivityErrorMessageState, action: PayloadAction<string>) => {
      state.claimActivityErrorMessage = action.payload;
    },
    removeErrorMessage: (state: ClaimActivityErrorMessageState, action: PayloadAction<string>) => {
      state.claimActivityErrorMessage = "";
    },
    addAccountErrorMessage: (state: ClaimActivityErrorMessageState, action: PayloadAction<string>) => {
      state.accountErrorMessage = action.payload;
    },
    removeAccountErrorMessage: (state: ClaimActivityErrorMessageState, action: PayloadAction<string>) => {
      state.accountErrorMessage = "";
    },
    addSuccessMessage: (state: ClaimActivityErrorMessageState, action: PayloadAction<string>) => {
      state.successMessage = action.payload;
    },
    setWasNationalProgramsClicked: (state: ClaimActivityErrorMessageState, action: PayloadAction<string>) => {
      state.wasNationalProgramsClicked = action.payload;
    },
    setSubmitClicked: (state: ClaimActivityErrorMessageState, action: PayloadAction<boolean>) => {
      state.submitClicked = action.payload;
    },
    setSavedParametersErrorMessage: (state: ClaimActivityErrorMessageState, action: PayloadAction<string>) => {
      state.savedParametersErrorMessage = action.payload;
    },
    setSavedParametersDuplicateMessage: (state: ClaimActivityErrorMessageState, action: PayloadAction<string>) => {
      state.savedParametersDuplicateMessage = action.payload;
    },
    setSavedParametersEmptyMessage: (state: ClaimActivityErrorMessageState, action: PayloadAction<string>) => {
      state.savedParametersEmptyMessage = action.payload;
    },
    setSavedParametersSuccessfulRenameMessage: (state: ClaimActivityErrorMessageState, action: PayloadAction<string>) => {
      state.savedParametersSuccessfulRenameMessage = action.payload;
    },
    setSearchResultsClicked: (state: ClaimActivityErrorMessageState, action: PayloadAction<boolean>) => {
      state.searchResultsClicked = action.payload;
    }
  }
});

// Action creators
export const { addErrorMessage, removeErrorMessage, removeAccountErrorMessage, addAccountErrorMessage, addSuccessMessage, showClaimActivityErrorMessage, setWasNationalProgramsClicked, setSubmitClicked, setSavedParametersErrorMessage, setSavedParametersDuplicateMessage, setSavedParametersEmptyMessage, setSavedParametersSuccessfulRenameMessage, setSearchResultsClicked} = claimActivityErrorMsgSlice.actions;

export default claimActivityErrorMsgSlice.reducer;
