import React from 'react';

interface OktaConfig {
  issuer: string,
  clientId: string,
  redirectUri?: string,
  pkce: boolean
}
interface AppConfig {
  REACT_APP_API_PRESENTATION_URL: string,
  REACT_APP_ST_ETRACER_URL: string,
  REACT_APP_ST_CLAIM_STATUS_URL: string,
  REACT_APP_ST_CLAIM_PORTAL_URL: string,
  REACT_APP_ST_CLAIM_REPORT_URL: string,
  REACT_APP_DOMAIN_URL: string,
  TEALIUM_ENV?: string,
  APPLICATION: string,
  readOnlyGroup: string,
  readWriteGroup: string
}

export interface AppContextProps {
  oktaConfig?: OktaConfig;
  appConfig?: AppConfig;
}

export const AppContext = React.createContext<AppContextProps>({});

export function useAppConfig() {
  const appContext = React.useContext(AppContext);
  return appContext;
}
