import { TdsNav, TdsNavItem, TdsNavList, TdsNavToggle } from '@trv-tds/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { AppContext } from '../../context/appContext';
import { useContext } from 'react';

const SideNavigation = () => {
  const { isNationalAccountUser, isTracerUser, isInternalUser, roleCodes} = useAuth();
  const defaultAppConfig = { REACT_APP_ST_CLAIM_STATUS_URL: undefined, REACT_APP_ST_ETRACER_URL: undefined }
  const { appConfig: { REACT_APP_ST_CLAIM_STATUS_URL, REACT_APP_ST_ETRACER_URL } = defaultAppConfig } = useContext(AppContext)
  const [searchParams, _] = useSearchParams();
  const page = searchParams.get("page")
  const navigate = useNavigate();

  const NavItem = ({ pageTitle }: { pageTitle: string }) => {
    const pageName = pageTitle.split(" ").join("")
    const isCurrent = pageName === "Home" && !page || page === pageName
    const pageLink = pageName === "Home" ? "/" : "/?page=" + pageName;
    return <TdsNavItem
      className={isCurrent ? "current-link-ancestor current" : ""}
      onClick={() => navigate(pageLink)}
    >
      <a>{pageTitle}</a>
    </TdsNavItem>
  }

  function hasNatProgramsRoleCode(){
    const hasRoleCode=roleCodes?.filter((element) =>{ 
      return element.toUpperCase() === 'ELRS1' || element.toUpperCase() === 'NEPVBILOSS' || element.toUpperCase() === 'NLOSSONLY';
    })
    return hasRoleCode?.length>0;
  }

  return (
    <div className='sidenavigation-main-div'>
      <TdsNav variant='left' variant-at='0' style={{ width: '280px' }}>
        <TdsNavList>
          <TdsNavItem>
            <TdsNavToggle expanded className='active_link'>
              REPORTS
            </TdsNavToggle>
            <TdsNavList>
              <NavItem pageTitle="Home" />
              <NavItem pageTitle="Detail Loss" />
              <NavItem pageTitle="Financial Summary" />
              <NavItem pageTitle="Detail Changes" />
              <NavItem pageTitle="Claim Lag Analysis" />
              <NavItem pageTitle="Insured Retention" />
              {( hasNatProgramsRoleCode()) && (
                <NavItem pageTitle="National Programs" />
              )}
              <NavItem pageTitle="Policy Summary" />
              <NavItem pageTitle="Saved Parameters" />
            </TdsNavList>
          </TdsNavItem>
      
          <TdsNavItem>
            <TdsNavToggle className='active_link'>AVAILABLE APPLICATIONS</TdsNavToggle>
            <TdsNavList>
              {/* {roleCodes && roleCodes?.indexOf('csmp2') > -1 && ( */}
                <TdsNavItem>
                  <a href={REACT_APP_ST_CLAIM_STATUS_URL} target='_blank'>Claim Status</a>
                </TdsNavItem>
              {/* )} */}
              {isTracerUser && (
                <TdsNavItem>
                  <a href={REACT_APP_ST_ETRACER_URL} target='_blank'>e-Tracer</a>
                </TdsNavItem>
              )}
              <TdsNavItem>
                <a href='https://www.travelers.com/risk-control' target='_blank'>Risk Control</a>
              </TdsNavItem>
            </TdsNavList>
          </TdsNavItem>

          <TdsNavItem>
            <TdsNavToggle className='active_link'>RESOURCES</TdsNavToggle>
            <TdsNavList>
              <TdsNavItem>
                <a href='http://www.talispoint.com/travelers/ext/?lob=wc' target='_blank' className='active_link'>
                  Find a Provider</a>
              </TdsNavItem>
              <TdsNavItem >
                <a href='https://www.travelers.com/claims/manage-claim/workers-compensation-claim-process' target='_blank'>My Workers Comp Info</a>
              </TdsNavItem>
              <TdsNavItem>
                <a href='https://www.travelers.com/resources' target='_blank'>Transportation Safety</a>
              </TdsNavItem>
              <TdsNavItem >
                <a href='https://www.travelers.com/claims/manage-claim/workers-compensation/state-resources' target='_blank'>Workers Comp State Resources</a>
              </TdsNavItem>
            </TdsNavList>
          </TdsNavItem>
        </TdsNavList>
      </TdsNav>
    </div>
  );
};

export default SideNavigation;
