import {
  Box,
  BoxProps,
  Button,
  Checkbox,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  TableContainer,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react';
import { FocusableElement } from '@chakra-ui/utils';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FilterOption } from '../../../common/types';
import { useGetFilterLookupListQuery } from '../../../features/utils/filterLookupApiSlice';
import { FilterLookupResult } from '../../../features/utils/utils';
import { RootState } from '../../../store';
import { Loader } from '../Common/Loader';

type ReportFilteringResultsProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setShowReportFilteringResultsModal: Dispatch<SetStateAction<boolean>>;
  filter: FilterOption | undefined;
  filters: FilterOption[];
  setFilters: (filters: FilterOption[]) => void;
  finalFocusRef?: React.RefObject<FocusableElement>;
};

export default function ReportFilteringResults({ isOpen, setIsOpen, setShowReportFilteringResultsModal, filter, filters, setFilters, finalFocusRef }: ReportFilteringResultsProps) {
  const [filterLookupOptions, setFilterLookupOptions] = useState<FilterLookupResult[] | undefined>();
  const [localFilters, setLocalFilters] = useState(filters)
  const activeFilter = useSelector((state: RootState) => state.filterLookup.activeFilter);

  const toggleRecord = (record: FilterLookupResult) => {
    if (filter) {
      const currentFilter = localFilters.filter((_filter) => _filter.filterKey === filter.filterKey)[0];
      const excludedFilters = localFilters.filter((_filter) => _filter.filterKey !== filter.filterKey);
//      const hasSameValue = excludedFilters.filter((_filter)=>_filter.field===currentFilter.field && _filter.operator===currentFilter.operator).length>0
      if (currentFilter?.value.split(",").includes(record.Code)) {
        let valueStr = currentFilter?.value.split(",");
        valueStr.splice(valueStr.indexOf(record.Code), 1);
        const newFilter = { ...currentFilter, value: valueStr.join(",") }
        setLocalFilters(([...excludedFilters, newFilter]).sort((a, b) => (a.filterKey ?? 1) - (b.filterKey ?? 1)))
      } else {
        const newFilter = { ...currentFilter, value: currentFilter?.value + (currentFilter?.value === "" ? "" : ",") + record.Code }
        setLocalFilters(([...excludedFilters, newFilter]).sort((a, b) => (a.filterKey ?? 1) - (b.filterKey ?? 1)))
      }
    }
  }

  const fieldNameArg = activeFilter.dbValue;
  const orgLevelCriteria = {
    'LimitingCriteria': {
      'FieldName': activeFilter.orgLevel,
      'Selection': activeFilter.selection,
      'AccountEffectiveDate': activeFilter.acctEffDt
    }
  };
  const lossCriteria =

  {
    'LimitingCriteria': {
      'FieldName': fieldNameArg
    },
  };
  const { data: filterLookupData, isSuccess, isLoading, isError, error } = useGetFilterLookupListQuery(isActiveFilterValidDate() ? orgLevelCriteria : lossCriteria);

  useEffect(() => {
    if (isError || error) {
      console.log(error);
    }
    if (!isLoading && isSuccess && filterLookupData) {
      console.log('filter:' + fieldNameArg);
      const { result } = filterLookupData;
      setFilterLookupOptions(result);
    }
  }, [filterLookupData]);

  function isActiveFilterValidDate() {
    if (activeFilter.acctEffDt) {
      return validDateFormat(activeFilter.acctEffDt.substring(0, 10));
    }
    return false;
  }
  function validDateFormat(targetVal: String) {
    const regex = new RegExp("[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]");
    return regex.test(JSON.stringify(targetVal));
  }

  function isAllSelected() {
    if (!filter) return false;
    const allCodes = filterLookupOptions?.map((option) => option.Code) ?? [];
    const currentFilter = localFilters.filter((_filter) => _filter.filterKey === filter.filterKey)[0];
    if (allCodes.length === currentFilter.value.split(",").length) {
      return true;
    } else {
      return false
    }
  }

  function toggleSelectAll() {
    if (filter) {
      const allCodes = filterLookupOptions?.map((option) => option.Code) ?? [];
      const currentFilter = localFilters.filter((_filter) => _filter.filterKey === filter.filterKey)[0];
      const excludedFilters = localFilters.filter((_filter) => _filter.filterKey !== filter.filterKey);
      if (isAllSelected()) {
        // Set all to empty
        setLocalFilters(([...excludedFilters, { ...currentFilter, value: "" }]).sort((a, b) => (a.filterKey ?? 1) - (b.filterKey ?? 1)))
      } else {
        // Set all to checked
        const newFilter = { ...currentFilter, value: allCodes.join(",") ?? "" }
        setLocalFilters(([...excludedFilters, newFilter]).sort((a, b) => (a.filterKey ?? 1) - (b.filterKey ?? 1)))
      }
    }
  }

  const addFilterSelections = () => {
    setFilters(localFilters)
    setIsOpen(false);
  };


  return (
    <Modal
      closeOnOverlayClick={false}
      motionPreset='slideInBottom'
      onClose={() => { setShowReportFilteringResultsModal(false); }}
      isOpen={isOpen}
      size="lg"
      finalFocusRef={finalFocusRef}
      isCentered
    >
      <ModalOverlay />
      <ModalContent maxW='700'>
        <ModalHeader textStyle="h1" textAlign="center" pb={0}>Report Filtering</ModalHeader>
        <ModalCloseButton border="none" cursor="pointer" aria-label='Dismiss Claim Activity e-Alerts' />
        <Divider />
        <ModalBody >
          <Box minH={250}>
            {isSuccess && (
              <Box fontSize="sm" textAlign="right" mb={4}>
              </Box>
            )}
            {(isLoading || isError || !filterLookupData) &&
              <Loader p={50} />
            }
            {!isLoading && !isError && filterLookupData && (
              <TableContainer overflowX="unset" overflowY="scroll" h={350}>
                <Table data-testid="account-search-results-table" size="sm" variant='simple' >
                  <Thead borderBottomStyle="solid" borderBottomWidth={2} borderBottomColor="black" position="sticky" top={0} zIndex="docked" className='tbl-list-header'>
                    <Tr className='tbl-header-seprator'><Th className='tbl-header' colSpan={3}>&nbsp;</Th></Tr>
                    <Tr>
                      <Th maxW={200}>
                        <Checkbox
                          aria-label='select all'
                          colorScheme='gray'
                          onChange={(e) => toggleSelectAll()}
                          isChecked={isAllSelected()}>
                        </Checkbox>
                      </Th>
                      <Th>
                        DESCRIPTION
                      </Th>
                      <Th>
                        CODE
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody data-testid="account-search-results-table-body" className='tbl-list-scroll'>
                    {filterLookupOptions?.map((record, index) => {
                      const { Text, Code } = record;
                      const currentFilter = filter && localFilters.filter((_filter) => _filter.filterKey === filter.filterKey)[0];
                      const isChecked = currentFilter && currentFilter.value.split(",").includes(Code);
                      return (
                        <Tr key={`id-${index}`}>
                          <Td w={2}>
                          <Checkbox
                              key={Code}
                              aria-label='select account listing'
                              colorScheme='gray'
                              onChange={(e) => toggleRecord(record)}
                              // isChecked={currentFilter && currentFilter.value.split(",").includes(Code)}>
                              isChecked={isChecked}>
                              </Checkbox>
                          </Td>
                          <Td fontSize="sm">
                            {Text}
                          </Td>
                          <Td fontSize="sm">
                            {Code}
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={addFilterSelections}
            colorScheme='brand' ml={3}>
            Select
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
