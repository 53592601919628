import { apiSlice } from '../../app/api/apiSlice';
import { API, REQ_TYPE } from '../../common/constants';
import { AuthenticatedGetQuery, AuthenticatedQuery, OptionalReportData, SavedParameterResponse } from '../../common/types';
import { SavedParametersAPIResults } from '../../components/Shared/SavedParameters/SavedParametersData';


export const savedParametersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSavedParameters: builder.query<SavedParametersAPIResults, AuthenticatedQuery>({
      query: (arg) => {
        return {
          method: REQ_TYPE.POST,
          url: API.SAVED_PARAMS,
          body: arg,
        };
      },
      //@ts-ignore
      providesTags: [{ type: "SavedParameters" } as const],
    }),
    updateParameters: builder.mutation<SavedParametersAPIResults, OptionalReportData>({
      query: (arg) => {
        return {
          method: REQ_TYPE.POST,
          url: API.UPDATE_PARAM,
          body: arg,
        };
      },
      // @ts-ignore
      invalidatesTags: [{ type: "SavedParameters" } as const],
      transformResponse: (response: any) => response,
    }),
    savedParameters: builder.mutation<SavedParameterResponse, AuthenticatedQuery>({
      query: (arg) => {
        return {
          method: REQ_TYPE.POST,
          url: API.SAVED_PARAMS,
          body: arg,
        };
      },
      transformResponse: (response: any) => response.result,
    }),
    getReportSavedParameters: builder.mutation<any, AuthenticatedGetQuery>({
      query: (arg) => {
        return {
          method: REQ_TYPE.POST,
          url: API.GET_REPORTS_SAVED_PARAMS,
          body: arg,
        };
      },
      transformResponse: (response: any) => response,
    }),
    getCriteriaSavedParameters: builder.mutation<any, AuthenticatedGetQuery>({
      query: (arg) => {
        return {
          method: REQ_TYPE.POST,
          url: API.GET_CRITERIA_SAVED_PARAMS,
          body: arg,
        };
      },
      transformResponse: (response: any) => response,
    }),
  }),
});

export const { useGetSavedParametersQuery, useUpdateParametersMutation, useSavedParametersMutation, useGetReportSavedParametersMutation, useGetCriteriaSavedParametersMutation } = savedParametersApiSlice;
