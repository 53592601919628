import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import { TdsField } from "@trv-tds/react";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BLANK_FILTER,
  accountIdentificationOptions,
} from "../../../common/constants";
import {
  FilterOption,
  AccountOrgLevelData,
  UserInformation,
} from "../../../common/types";
import { addSuccessMessage } from "../../../features/claimActivity/claimActivityErrorMsgSlice";
import { updateActiveFilter } from "../../../features/formSections/activeFilterSlice";
import { useGetFilterLookupListQuery, useGetOrganizationLevelDataQuery } from "../../../features/utils/filterLookupApiSlice";
import {
  DowndownOptions,
  limitOperatorOptions,
} from "../../../features/utils/utils";
import { useGetDropdownListQuery } from "../../../features/utils/utisApiSlice";
import { RootState } from "../../../store";
import { AccIdentification } from "../AgentAccountSearch/agentAccount";
import { AddButton } from "../Common/AddButton";
import { AlertMessage } from "../Common/AlertMessage";
import { LoadingSkeleton } from "../Common/LoadingSkeleton";
import { RemoveButton } from "../Common/RemoveButton";
import useAuth from "../../../hooks/useAuth";

type FilterSectionProps = {
  filters: FilterOption[];
  setFilters: (filters: FilterOption[]) => void;
  onShowModal?: Function;
  activeFilter?: FilterOption;
  setActiveFilter?: Dispatch<SetStateAction<FilterOption | undefined>>;
  setOrgLevelData?: Function;
  acctOrgLevelData?: AccountOrgLevelData;
  setAcctOrgLevelData?: Function;
  pageName?: String;
};

export const FilterSection = ({
  filters,
  setFilters,
  onShowModal,
  setActiveFilter,
  setOrgLevelData,
  acctOrgLevelData,
  pageName,
}: FilterSectionProps) => {
  const userAccountIdentification: AccIdentification = useSelector(
    (state: RootState) => state.agentAccount.accountIdentification
  );
  const {
    data: dropDownData,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useGetDropdownListQuery();
  const dropDownOptions = dropDownData?.option?.filter((el) => {
    return el._Type === "Both";
  });

  const [limitFieldOptions, setLimitFieldOptions] = useState<
    DowndownOptions[] | null | undefined
  >(dropDownOptions);
  const [acctNumOrgData, setAcctNumOrgData] = useState<string>("");
  const dispatch = useDispatch();
  const limitingCriteria = {
    LimitingCriteria: {
      Selection:
        userAccountIdentification.accountIdentificationSelections.length > 0
          ? userAccountIdentification.accountIdentificationSelections[0]
          : "",
    },
  };
  const { claimActivityErrorMessage: claimActivityListError } = useSelector(
    (state: RootState) => state.claimActivity
  );
  const { profileData } = useAuth();

  const {
    data: orgLevelData,
    isSuccess: isOrgSuccess,
    isLoading: isOrgLoading,
    isError: isOrgError,
    error: orgError,
  } = useGetOrganizationLevelDataQuery(limitingCriteria, {
    skip: claimActivityListError !== "",
  });
  useEffect(() => {
    let newlimitFieldOptions = limitFieldOptions;
    //@ts-ignore
    if (pageName && pageName === 'DetailChanges' && newlimitFieldOptions?.findIndex((e) => e._text === "Risk State Historical") < 0) {
      let insertIndex = newlimitFieldOptions?.findIndex(
        (e) => e._text === "Risk State"
      );
      let rso: DowndownOptions = {
        _value: "ST_CD",
        _text: "Risk State Historical",
        _Type: "Both",
        _current: "T2",
        _history: "",
        _unquoted: "",
      };
      //@ts-ignore
      newlimitFieldOptions?.splice(insertIndex + 1, 0, rso);
    }
    if (pageName && pageName === 'PolicySummary') {
      let blankOption: DowndownOptions = {
        _value: "",
        _text: "",
        _Type: "Both",
        _current: "T2",
        _history: "",
        _unquoted: "",
      };
      let polNbr: DowndownOptions = {
        _value: "POL_NBR",
        _text: "Policy Number",
        _Type: "Both",
        _current: "T2",
        _history: "",
        _unquoted: "",
      };
      let polYear: DowndownOptions = {
        _value: "POL_YR",
        _text: "Policy Year",
        _Type: "Both",
        _current: "T2",
        _history: "",
        _unquoted: "",
      };
      let policySummaryLimitFieldOptions: DowndownOptions[] = [blankOption, polNbr, polYear];
      setLimitFieldOptions(policySummaryLimitFieldOptions);
    }

  }, []);

  useEffect(() => {
    if (pageName && pageName !== 'PolicySummary') {
      if (
        !isLoading &&
        isSuccess &&
        dropDownData &&
        !limitFieldOptions &&
        profileData
      ) {
        const { option } = dropDownData;
        const newlimitFieldOptions = option.filter((el) => {
          return el._Type === "Both";
        });
        if (pageName && pageName === 'DetailChanges' && newlimitFieldOptions?.findIndex((e) => e._text === "Risk State Historical") < 0) {
          let insertIndex = newlimitFieldOptions?.findIndex(
            (e) => e._text === "Risk State"
          );
          let rso: DowndownOptions = {
            _value: "ST_CD",
            _text: "Risk State Historical",
            _Type: "Both",
            _current: "T3",
            _history: "",
            _unquoted: "",
          };
          newlimitFieldOptions?.splice(insertIndex + 1, 0, rso);
        }
        setLimitFieldOptions(newlimitFieldOptions);
      }
    }
  }, [dropDownData, profileData]);

  useEffect(() => {
    if (pageName && pageName !== 'PolicySummary') {
      if (
        userAccountIdentification.accountIdentificationMethod ===
        accountIdentificationOptions.ProducerCode
      ) {
        //remove Claim Number Other, Location Code, and Policy Number Other
        let limitFieldReduced = limitFieldOptions?.filter(
          (e) => e._text !== "Claim Number Other"
        );
        limitFieldReduced = limitFieldReduced?.filter(
          (e) => e._text !== "Policy Number Other"
        );
        limitFieldReduced = limitFieldReduced?.filter(
          (e) => e._text !== "Location Code"
        );
        setLimitFieldOptions(limitFieldReduced);
      } else {
        //Add back in Claim Number Other, Location Code, and Policy Number Other
        let alreadyExists = limitFieldOptions?.findIndex(
          (e) => e._text === "Claim Number Other"
        );
        if (alreadyExists && alreadyExists === -1) {
          let insertIndex = limitFieldOptions?.findIndex(
            (e) => e._text === "Claim Number"
          );
          let limitFieldExpanded = limitFieldOptions?.map((obj) => ({ ...obj }));
          if (insertIndex && insertIndex > -1) {
            let cno: DowndownOptions = {
              _value: "OC_CLM_NBR",
              _text: "Claim Number Other",
              _Type: "Both",
              _current: "T2",
              _history: "",
              _unquoted: "",
            };
            limitFieldExpanded?.splice(insertIndex + 1, 0, cno);
          }
          insertIndex = limitFieldExpanded?.findIndex(
            (e) => e._text === "Policy Number - DRE"
          );
          let pno: DowndownOptions = {
            _value: "OC_POL_NBR",
            _text: "Policy Number Other",
            _Type: "Both",
            _current: "T2",
            _history: "",
            _unquoted: "",
          };
          if (insertIndex && insertIndex > -1) {
            limitFieldExpanded?.splice(insertIndex + 1, 0, pno);
          }
          insertIndex = limitFieldExpanded?.findIndex(
            (e) => e._text === "Line of Insurance"
          );
          let loc: DowndownOptions = {
            _value: "ORG_CD",
            _text: "Location Code",
            _Type: "Both",
            _current: "T1",
            _history: "T3",
            _unquoted: "",
          };
          if (insertIndex && insertIndex > -1) {
            limitFieldExpanded?.splice(insertIndex + 1, 0, loc);
          }
          setLimitFieldOptions(limitFieldExpanded);
        }
      }
    }
  }, [userAccountIdentification.accountIdentificationMethod]);

  useEffect(() => {
    if (pageName && pageName !== 'PolicySummary') {
      let limitFieldReduced = limitFieldOptions?.filter((e) => e._Type !== "ORG");
      setLimitFieldOptions(limitFieldReduced);
      setAcctNumOrgData(
        userAccountIdentification.accountIdentificationSelections.length > 0
          ? userAccountIdentification.accountIdentificationSelections[0]
          : ""
      );
      if (
        userAccountIdentification.accountIdentificationMethod ===
        "Account Number" &&
        userAccountIdentification.accountIdentificationSelections.length > 0
      ) {
        //first need to create downdownoptions structure and populate org level headers into the structure and then add to drop down
        if (
          !isOrgLoading &&
          isOrgSuccess &&
          !isOrgError &&
          orgLevelData &&
          limitFieldOptions
        ) {
          if (setOrgLevelData) setOrgLevelData(orgLevelData);
          const { Headers, AccountEffectiveDate, AccountNumber } = orgLevelData;
          setAcctNumOrgData(
            userAccountIdentification.accountIdentificationSelections[0]
          );
          let ddHeaders: DowndownOptions[] = [];
          for (let x = 0; x < Headers.length; x++) {
            if (Headers[x] !== null)
              ddHeaders[x] = {
                _text: Headers[x],
                _value: "ORG" + (x + 1),
                _Type: "ORG",
                _history: AccountEffectiveDate,
                _unquoted: AccountNumber,
                _current: "",
              };
          }
          let existingDDData = limitFieldOptions?.filter(
            (e) => e._Type !== "ORG"
          );
          for (let y = 0; y < ddHeaders.length; y++) {
            existingDDData?.splice(existingDDData.length + 1, 0, ddHeaders[y]);
          }

          setLimitFieldOptions(existingDDData);
        }
      }
    }
  }, [orgLevelData]);

  useEffect(() => {
    if (pageName && pageName !== 'PolicySummary') {
      if (acctOrgLevelData) {
        let ddHeaders: DowndownOptions[] = [];
        if (acctOrgLevelData?.Orgs?.Headers) {
          for (let x = 0; x < acctOrgLevelData.Orgs.Headers.length; x++) {
            if (acctOrgLevelData.Orgs.Headers[x] !== null)
              ddHeaders[x] = {
                _text: acctOrgLevelData.Orgs.Headers[x].toString(),
                _value: "ORG" + (x + 1),
                _Type: "ORG",
                _history: "",
                _unquoted: "",
                _current: "",
              };
          }
        }
        let existingDDData = limitFieldOptions?.filter((e) => e._Type !== "ORG");
        for (let y = 0; y < ddHeaders.length; y++) {
          existingDDData?.splice(existingDDData.length + 1, 0, ddHeaders[y]);
        }

        setLimitFieldOptions(existingDDData);
      }
    }
  }, [acctOrgLevelData]);


  function validateFields() {
    let hasBlankRow = false;
    for (let x = 0; x < filters.length; x++) {
      if (filters[x].field === "") {
        if (x === 0 && filters.length === 1) {
          let f: FilterOption[] = [BLANK_FILTER];
          setFilters(f);
        } else {
          hasBlankRow = true;
        }
      }
    }
    if (hasBlankRow) {
      let n: FilterOption[] = [];
      for (let j = 0; j < filters.length; j++) {
        if (filters[j].field !== "") {
          n.push(filters[j]);
        }
      }
      setFilters(n);
    }
  }
  function enableFilterLookup(fieldStr: String) {
    if ((acctOrgLevelData || acctNumOrgData !== "") && fieldStr !== "") {
      const hasOrgType = limitFieldOptions?.filter(
        (a) => a._text === fieldStr && a._Type === "ORG"
      );
      if (hasOrgType && hasOrgType?.length > 0) {
        return true;
      }
    }
    return (
      fieldStr === "Accident Cause" ||
      fieldStr === "Accident Result" ||
      fieldStr === "Accident State" ||
      fieldStr === "Adjusting Office" ||
      fieldStr === "Benefit State" ||
      fieldStr === "Body Part" ||
      fieldStr === "Claim Status" ||
      fieldStr === "File Prefix" ||
      fieldStr === "Incident Indicator" ||
      fieldStr === "IPG Line of Insurance" ||
      fieldStr === "Line of Insurance" ||
      fieldStr === "Risk State" ||
      fieldStr === "Subline of Insurance" ||
      fieldStr === "Risk State Historical"
    );
  }

  function getOrgLevel(valueStr: String) {
    const level = limitFieldOptions?.filter((e) => e._text === valueStr);
    if (level && level?.length > 0) {
      return level[0]._value;
    }
    return "";
  }
  function getAccountNumber(valueStr: String) {
    const acctNum = limitFieldOptions?.filter((e) => e._text === valueStr);
    if (acctNum && acctNum?.length > 0) {
      return acctNum[0]._unquoted;
    }
    return "";
  }
  function getEffDt(valueStr: String) {
    const effDt = limitFieldOptions?.filter((e) => e._text === valueStr);
    if (effDt && effDt?.length > 0) {
      return effDt[0]._history;
    }
    return "";
  }

  const addFilter = () => {
    if (filters.length < 5) {
      const highestNumber = Math.max(...filters.map((o) => o.filterKey || 1));
      const newFilter = { ...BLANK_FILTER, filterKey: highestNumber + 1 };
      setFilters([...filters, newFilter]);
    }
  };

  const removeFilter = (row: number) => {
    // removes filter and re-orders filter keys
    setFilters(
      filters
        .filter((_, num) => num !== row)
        .map((_filter, index) => {
          return { ..._filter, filterKey: index };
        })
    );
  };
  const resetFilter = (resetFilterRow: FilterOption) => {
    dispatch(addSuccessMessage(""));
    setFilters([
      ...filters.map((filter) => {
        if (filter.filterKey === resetFilterRow.filterKey) {
          return { ...BLANK_FILTER, filterKey: filter.filterKey };
        } else {
          return filter;
        }
      }),
    ]);
  };
  if (error) {
    console.error(error);
  }
  function filterLookupClick(filter: FilterOption, e: any) {
    onShowModal?.();
    //@ts-ignore
    e.target.blur();
    if (setActiveFilter) {
      const { value, ...originalProperties } = filter;
      setActiveFilter({
        ...originalProperties,
        value,
      });
    }
    dispatch(updateActiveFilter(filter));
  }
  function getLimitFieldValue(selectionVal: String) {
    if (limitFieldOptions) {
      for (let i = 0; i < limitFieldOptions?.length; i++) {
        if (limitFieldOptions[i]._text === selectionVal)
          return limitFieldOptions[i]._value;
      }
    }
    limitFieldOptions?.find((obj) => {
      if (obj._text === selectionVal) return obj._value;
    });
  }

  const onFilterBlur = (filter: FilterOption) => {
    const formattedFilters = filters
      // .filter((_filter) => _filter === filter)
      .map((_filter) => {
        const newFilter = {
          ..._filter,
          isDirty: _filter === filter ? true : _filter.isDirty || false,
        };
        return newFilter;
      });
    setFilters(formattedFilters);
  };

  return isLoading ? (
    <LoadingSkeleton />
  ) : isError ? (
    <AlertMessage message={"Error loading filters"} />
  ) : (
    <Box data-testid="filter-section">
      <Text as="h4" textStyle="h4">
        Filters
      </Text>
      <AddButton
        data-testid="add-filter-button"
        onClick={addFilter}
        text="Add a New Filter"
        disabled={filters.length > (pageName === 'PolicySummary' ? 1 : 4)}
        aria-label="add-filter-button"
      />
      {filters?.map((filter, index) => {
        return (
          <Flex key={index} w={850}>
            <Stack
              direction={["column", "column", "row"]}
              spacing={2}
              mt={4}
              ml={0}
              w={400}
            >
              <RemoveButton
                data-testid="remove-filter-button"
                onClick={() => {
                  removeFilter(index);
                }}
                text="Remove Filter"
                hidden={index === 0}
                aria-label="remove-filter-button"
              />
              <FormControl>
                <TdsField label="Field">
                  <select
                    style={{
                      width: "200px",
                      height: "2rem",
                      verticalAlign: "top",
                      padding: "0 0 0 .75rem",
                      fontSize: "1rem",
                    }}
                    className="tds-field__input"
                    aria-label="Field"
                    name="tds-field__input"
                    data-testid="filter-field-select"
                    value={filter.field as string}
                    onChange={(event) => {
                      dispatch(addSuccessMessage(""));
                      if (event.target.value === "") {
                        resetFilter(filter);
                      } else {
                        setFilters(
                          filters.map((_filter, i) =>
                            i === index
                              ? {
                                ..._filter,
                                field: event.target.value,
                                dbValue: getLimitFieldValue(
                                  event.target.value
                                ),
                                operator: "Equal To",
                                value: "",
                                isDirty: false,
                                orgLevel: acctOrgLevelData
                                  ? getOrgLevel(event.target.value)
                                  : acctNumOrgData !== ""
                                    ? getOrgLevel(event.target.value)
                                    : "",
                                acctEffDt: acctOrgLevelData
                                  ? acctOrgLevelData?.Orgs?.AccountEffectiveDate.toString()
                                  : acctNumOrgData !== ""
                                    ? getEffDt(event.target.value)
                                    : "",
                                selection: acctOrgLevelData
                                  ? acctOrgLevelData?.FirstAccount
                                  : acctNumOrgData !== ""
                                    ? getAccountNumber(event.target.value)
                                    : "",
                              }
                              : _filter
                          )
                        );
                      }
                    }}
                  >
                    {limitFieldOptions?.map((element, index) => (
                      <option
                        key={index}
                        selected={filter.field === element._text}
                        value={element._text}
                      >
                        {element._text}
                      </option>
                    ))}
                  </select>
                  <div className="tds-field--error" style={{ marginBottom: 0 }}>
                    <div
                      id="filter-section-invalid-date-operator-error"
                      aria-atomic="true"
                      className="tds-field__message"
                      role="alert"
                      hidden={!filter.invalidDateOperatorError}
                    >
                      <p style={{ fontSize: "14px" }}>
                        Cannot use Contains, Omits or Starts With on a date
                        field
                      </p>
                    </div>
                  </div>
                </TdsField>
              </FormControl>
              <FormControl>
                <TdsField label="Operator">
                  <select
                    style={{
                      width: "95%",
                      height: "2rem",
                      verticalAlign: "top",
                      padding: "0 0 0 .75rem",
                      fontSize: "1rem",
                      textAlign: "left",
                    }}
                    className="tds-field__input"
                    aria-label="Operator"
                    name="tds-field__input"
                    data-testid="filter-operator-select"
                    value={filter.operator as string}
                    onChange={(event) => {
                      dispatch(addSuccessMessage(""));
                      setFilters(
                        filters.map((_filter, i) =>
                          i === index
                            ? {
                              ..._filter,
                              operator: event.target.value,
                            }
                            : _filter
                        )
                      );
                    }}
                  >
                    <option defaultValue=""></option>
                    {limitOperatorOptions.map((element, index) => (
                      <option key={index} value={element.label}>
                        {element.label}
                      </option>
                    ))}
                  </select>
                  <div className="tds-field--error" style={{ marginBottom: 0 }}>
                    <div
                      id="filter-section-missing-operator-error"
                      aria-atomic="true"
                      className="tds-field__message"
                      role="alert"
                      hidden={filter.operator !== "" || filter.field === ""}
                    >
                      <p style={{ fontSize: "14px" }}>
                        Please enter valid filter criteria
                      </p>
                    </div>
                  </div>
                  <div className="tds-field--error" style={{ marginBottom: 0 }}>
                    <div
                      id="filter-section-missing-operator-error"
                      aria-atomic="true"
                      className="tds-field__message"
                      role="alert"
                      hidden={!filter.multipleValuesGTError}
                    >
                      <p style={{ fontSize: "14px" }}>
                        Enter only one value when using Greater Than operator
                      </p>
                    </div>
                  </div>
                  <div className="tds-field--error" style={{ marginBottom: 0 }}>
                    <div
                      id="filter-section-missing-operator-error"
                      aria-atomic="true"
                      className="tds-field__message"
                      role="alert"
                      hidden={!filter.multipleValuesLTError}
                    >
                      <p style={{ fontSize: "14px" }}>
                        Enter only one value when using Less Than operator
                      </p>
                    </div>
                  </div>
                </TdsField>
              </FormControl>
            </Stack>
            <Stack
              direction={["column", "column", "row"]}
              spacing={2}
              mt={4}
              ml={0}
              w={450}
            >
              <FormControl>
                <TdsField
                  label="Value"
                  data-testid="value-id"
                  message-aria-live="assertive"
                  alert={
                    filter.dateInvalidError ||
                      filter.dateFormatError ||
                      filter.yearFormatError
                      ? "error"
                      : "false"
                  }
                >
                  <InputGroup style={{ fontSize: "14px" }}>
                    {filter.value.length <= 20 && (
                      <Input
                        className={
                          "add-filters-value-input-field " +
                          (filter.dateInvalidError ||
                            filter.dateFormatError ||
                            filter.yearFormatError ||
                            filter.yearInvalidError
                            ? "tds-field-invalid"
                            : "tds-field-valid")
                        }
                        aria-label="value"
                        data-testid="value-id-input"
                        type="text"
                        onBlur={() => onFilterBlur(filter)}
                        onChange={(event) => {
                          dispatch(addSuccessMessage(""));
                          setFilters(
                            filters.map((_filter, i) =>
                              i === index
                                ? {
                                  ..._filter,
                                  isDirty: false,
                                  value: event.target.value
                                    .toUpperCase()
                                    .trim(),
                                }
                                : _filter
                            )
                          );
                        }}
                        autoFocus
                        value={filter.value as string}
                        style={{
                          float: "left",
                          width: "89%",
                          padding: "0 0 0 .75rem",
                        }}
                        w={10}
                      />
                    )}
                    {filter.value.length > 20 && (
                      <Textarea
                        className="add-filters-value-input-field"
                        aria-label="value"
                        autoFocus={true}
                        onBlur={() => onFilterBlur(filter)}
                        onFocus={(event) => {
                          event.target.selectionStart =
                            event.target.selectionEnd =
                            event.target.value.length + 1;
                        }}
                        onChange={(event) => {
                          event.stopPropagation();
                          dispatch(addSuccessMessage(""));
                          setFilters(
                            filters.map((_filter, i) =>
                              i === index
                                ? {
                                  ..._filter,
                                  value: event.target.value
                                    .toUpperCase()
                                    .trim(),
                                }
                                : _filter
                            )
                          );
                        }}
                        value={filter.value as string}
                        style={{
                          float: "left",
                          width: "89%",
                          padding: "0 0 0 .75rem",
                        }}
                        w={10}
                      />
                    )}
                    <InputRightElement w={12}>
                      <Tooltip
                        label="lookup"
                        closeOnClick
                        closeDelay={100}
                        isDisabled={!enableFilterLookup(filter.field)}
                      >
                        <IconButton
                          onClick={(e) => {
                            filterLookupClick(filter, e);
                          }}
                          isDisabled={!enableFilterLookup(filter.field)}
                          variant="outlineRight"
                          aria-label="submit search"
                          size="sm"
                          style={{
                            color: "rgb(118, 118, 118)",
                            border: "1px solid",
                            padding: "15px 10px",
                            width: "10%",
                          }}
                          icon={<SearchIcon />}
                        />
                      </Tooltip>
                    </InputRightElement>
                  </InputGroup>
                  <div className="tds-field--error" style={{ marginBottom: 0 }}>
                    <div
                      id="filter-section-missing-operator-error"
                      aria-atomic="true"
                      className="tds-field__message"
                      role="alert"
                      hidden={!filter.dateInvalidError}
                    >
                      <p style={{ fontSize: "14px" }}>Invalid date</p>
                    </div>
                    <div
                      id="filter-section-missing-operator-error"
                      aria-atomic="true"
                      className="tds-field__message"
                      role="alert"
                      hidden={!filter.dateFormatError}
                    >
                      <p style={{ fontSize: "14px" }}>
                        Date must be in MM/DD/YYYY format
                      </p>
                    </div>
                    <div
                      id="filter-section-missing-operator-error"
                      aria-atomic="true"
                      className="tds-field__message"
                      role="alert"
                      hidden={!filter.yearFormatError}
                    >
                      <p style={{ fontSize: "14px" }}>Format must be YYYY</p>
                    </div>
                    <div
                      id="filter-section-missing-operator-error"
                      aria-atomic="true"
                      className="tds-field__message"
                      role="alert"
                      hidden={!filter.yearInvalidError}
                    >
                      <p style={{ fontSize: "14px" }}>Invalid date</p>
                    </div>
                  </div>
                </TdsField>
              </FormControl>

              <FormControl w={20}>
                <Button
                  mt={7}
                  style={{ padding: "15px 20px", marginTop: "30px" }}
                  onChange={(event) => {
                    dispatch(addSuccessMessage(""));
                    setFilters(
                      filters.map((_filter, i) =>
                        i === index
                          ? { ..._filter, field: "", value: "", operator: "" }
                          : _filter
                      )
                    );
                    validateFields();
                  }}
                  onClick={() => {
                    resetFilter(filter);
                  }}
                  variant="outline"
                  size="md"
                >
                  Reset
                </Button>
              </FormControl>
            </Stack>
            {/* </Stack> */}
          </Flex>
        );
      })}
    </Box>
  );
};
export default FilterSection;
