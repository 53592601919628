import { Box, Spinner, Input, InputGroup, InputRightElement, Icon, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tooltip, Tr, Heading } from '@chakra-ui/react';
import { ReactComponent as Pencil124 } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/pencil-1-24.svg';
import { ReactComponent as SearchResultsDeleteAlertCloseIcon } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/x-1-12.svg';
import { TdsField, TdsLoadingSpinner } from '@trv-tds/react';
import saveAs from 'file-saver';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SavedParameterSearchResult } from '../../../common/types';
import { setSavedParametersErrorMessage, setSavedParametersDuplicateMessage, setSavedParametersEmptyMessage, setSavedParametersSuccessfulRenameMessage, setSearchResultsClicked } from '../../../features/claimActivity/claimActivityErrorMsgSlice';
import { useGetCriteriaSavedParametersMutation, useGetReportSavedParametersMutation, useUpdateParametersMutation } from '../../../features/savedParameters/savedParametersApiSlice';
import useAuth from '../../../hooks/useAuth';
import { RootState } from "../../../store";
import moment from 'moment';
import { AccIdentification } from '../AgentAccountSearch/agentAccount';
import { useAnalytics } from '@trv/bi-digital-web-analytics';
import { clickAnalytics } from "../Analytics/Analytics";
import getAnalyticsConfig from "../Analytics/AnalyticsConfig";



type SavedParametersSearchResultsProps = {
  searchResults: SavedParameterSearchResult[]
  showResults: boolean
  isLoading: boolean
  setRefresh: Dispatch<SetStateAction<boolean>>
  setShowDeletedSuccessMsg: Dispatch<SetStateAction<boolean>>
  currentId?: number|undefined;
  setCurrentId?: Dispatch<SetStateAction<number | undefined>>
};

const SavedParametersSearchResults = ({ searchResults, showResults, isLoading, setRefresh, setShowDeletedSuccessMsg, currentId, setCurrentId }: SavedParametersSearchResultsProps) => {
  const { profileData } = useAuth();
  const [showRenameForm, setShowRenameForm] = useState<boolean>(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
  const [checkedRow, setCheckedRow] = useState<number>();
  const [submitLoading, setSubmitLoading] = useState(false);
  const navigate = useNavigate();
  const [postGetReportSavedParamsData] = useGetReportSavedParametersMutation();
  const [getCriteriaSavedParamsData] = useGetCriteriaSavedParametersMutation();
  const [saveParamaters] = useUpdateParametersMutation();
  const [displayOverrideOrgCheck, setDisplayOverrideOrgCheck] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [scroll, setScroll] = useState(false);
//  const [continueOverride, setContinueOverride] = useState(false);
  let continueOverride = false;
  const [actionToDo, setActionToDo] = useState('');
  const dispatch = useDispatch();
  const duplicateMsg = useSelector(
    (state: RootState) => state.claimActivity.savedParametersDuplicateMessage
  );
  const emptyMsg = useSelector(
    (state: RootState) => state.claimActivity.savedParametersEmptyMessage
  );

  const _displayRename = useSelector(
    (state: RootState) => state.claimActivity.savedParametersSuccessfulRenameMessage
  );
  const searchResultsClicked = useSelector(
    (state: RootState) => state.claimActivity.searchResultsClicked
  );

  const [aFocus, setAFocus] = useState(false);

  const msgRef = useRef<HTMLInputElement>(null);
  const scrollToElement = () => {
    msgRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const userAccountIdentification: AccIdentification = useSelector(
    (state: RootState) => state.agentAccount.accountIdentification
  );

  const analytics = useAnalytics();



  useEffect(() => {
    if (scroll) {
      scrollToElement();
    }
    setScroll(false);
  }, [scroll]);
  interface RecordRowType {
    record: SavedParameterSearchResult;
    setIsEditing: Dispatch<SetStateAction<boolean>>
    setScroll: Dispatch<SetStateAction<boolean>>
  }
  const [allSubmits, setAllSubmits] = useState({})
  const handleClick = () => {
    dispatch(setSavedParametersSuccessfulRenameMessage(""));
  }
  const RecordRow = ({ record, setIsEditing, setScroll }: RecordRowType) => {
    const [rowName, setRowName] = useState(record.RPT_NM)
    const displayBlankReportNameCellMessage = rowName === '' || rowName === undefined;
    const displayDuplicateCellMessage = searchResults.filter(result => result.PARAM_ID !== record.PARAM_ID).map(result => result.RPT_NM).includes(rowName)
    const handleInputSubmit = async (e) => {
      setAFocus(false);
      dispatch(setSavedParametersDuplicateMessage(""));
      dispatch(setSavedParametersEmptyMessage(""));
    const changedText = e.target.value
      if (changedText !== record.RPT_NM) {
        setIsEditing(true);
        if (!displayBlankReportNameCellMessage && !displayDuplicateCellMessage) {
          if (changedText === '') {
            dispatch(setSavedParametersEmptyMessage("true"));
            setAFocus(true);
            setScroll(true);
          } else if (record.RPT_NM === changedText) {
            // do nothing. This is the case where the user clicked to edit but didn't change anything.
          } else {
            setAllSubmits({ [record.PARAM_ID]: true })
            dispatch(setSavedParametersEmptyMessage(""));
            let saveResult = await renameRow(changedText, record);
            if (await saveResult === '5') {
              dispatch(setSavedParametersDuplicateMessage("true"));
              setAFocus(true);
              setScroll(true);
            } else if (await saveResult === '6') {
              setScroll(true);
              dispatch(setSavedParametersSuccessfulRenameMessage("true"));
              setAFocus(true);

              setCurrentId && setCurrentId(undefined);            }
          }
          setAllSubmits({})
        } else if (displayBlankReportNameCellMessage) {
          setAFocus(true);
          setScroll(true);
          dispatch(setSavedParametersEmptyMessage("true"));
        } else if (displayDuplicateCellMessage) {
          setAFocus(true);
          setScroll(true);
          dispatch(setSavedParametersDuplicateMessage("true"));
        }
      }else{
        setCurrentId && setCurrentId(undefined);            
        
      }
    }
    const isSubmitting = allSubmits[record.PARAM_ID]
    return (<div>
          {record.PARAM_ID === currentId &&
          <InputGroup
          p={2}
          size='md'
          _hover={{ cursor: 'pointer' }}
          style={{wordWrap: 'break-word'}}
        >
          <Input
            type="text"
            isDisabled={isSubmitting}
            autoFocus={duplicateMsg === "true" || emptyMsg === "true" || aFocus}
            onChange={(e) => {
              dispatch(setSavedParametersDuplicateMessage(""));
              dispatch(setSavedParametersEmptyMessage(""));
              setRowName(e.target.value);
            }}
            value={rowName}
            defaultValue={rowName}
            onClick={(e) => {
              setAFocus(true);
              setCheckedRow(undefined);
              dispatch(setSavedParametersDuplicateMessage(""));
              dispatch(setSavedParametersEmptyMessage(""));
              e.preventDefault()
              e.stopPropagation()
              if (setCurrentId && currentId !== record.PARAM_ID) {
                setCurrentId(record.PARAM_ID)
              }
              handleClick()
            }}
            onBlur={handleInputSubmit}
            _hover={{cursor: 'pointer'}}
            onKeyDown={async (e) => {
              if (e.key === 'Enter') {
                await handleInputSubmit(e)
              }
            }}
            maxLength={62}
            style={{ cursor: 'pointer', fontSize: '12px', padding: '5px', border:'none', paddingRight: '30px', wordWrap: 'break-word'}}
          />

          <InputRightElement m={2} fontSize={12} 
            onClick={(e) => {
              setAFocus(true);
              setCheckedRow(undefined);
            }}
            style={{wordWrap: 'break-word'}}
            >
             <Pencil />
            {isSubmitting && <Spinner />}
          </InputRightElement>
        </InputGroup>
      }
      {record.PARAM_ID !== currentId &&
        <Box
          w="full"
          h="full"
          p={4}
          minH={12}
          onClick={(e) => {
            if (setCurrentId && currentId !== record.PARAM_ID) {
              setCurrentId(record.PARAM_ID)
            }
            handleClick()
            setCheckedRow(undefined);
            setAFocus(true);
          }}
          style={{ cursor: 'pointer', fontSize: '12px', position: 'relative', padding: '15px 30px 15px 15px', wordWrap: 'break-word'}}
        >
          {rowName}
          <div style={{cursor: 'pointer', position: 'absolute', right: '15px', top: '40%', wordWrap:'break-word'}}>
            <Pencil />
          </div>
        </Box>
      }

    </div>)
  }

  function Pencil() {
    return <Icon as={Pencil124} />
  }
  useEffect(() => {
    dispatch(setSavedParametersErrorMessage(""));
    if (!showResults || searchResultsClicked) {
      setCheckedRow(undefined);
      dispatch(setSearchResultsClicked(false));
    }

  }, [showResults])

  async function openPDF(pId: number) {
    const submitReportData = {
      UserInformation: profileData[0],
      ParameterCriteria: {
        ParamId: pId,
        OutputFormat: 0,
        OverrideOrgCheck: continueOverride
      }
    }
    setSubmitLoading(true)
    if (submitReportData) {
      //@ts-ignore
      const { data }: any = await postGetReportSavedParamsData(submitReportData);
      if(data?.messages[0]?.code === '105'
                && data?.messages[0]?.description === 'Orgs are changed'
              ) {
                setSubmitLoading(false);
                if(!displayOverrideOrgCheck){
                   setDisplayOverrideOrgCheck(true);
                   setActionToDo('submit');
                   return false;
                }else if(continueOverride){
                   continueOverride = false;
                  setDisplayOverrideOrgCheck(false);
                }
              }else{
              const moreThanSixMb: number = Number(data?.messages[0].code) || 0;
      const windowParams = `left=200, top=200, toolbar=0, status=0, width=${screen.width + (-200)}, height=${window.innerHeight}`;
      if (data && data.result) {
        if (moreThanSixMb == 1) {
          dispatch(setSavedParametersErrorMessage(""));
          console.log('Report file size is more than 6 MB.', data.result);
          const pdfURL: string = data.result;
          setDisplayOverrideOrgCheck(false);
          window.open(pdfURL, 'report.pdf', windowParams);
        } else {
          dispatch(setSavedParametersErrorMessage(""));
          const blob = base64toBlob(`data:application/pdf;base64, ${data.result}`);
          const fileURL = window.URL.createObjectURL(blob);
          console.log('URL ::', fileURL.substring(5));
          setDisplayOverrideOrgCheck(false);
          window.open(fileURL, 'primary', windowParams);
        }
      } else if (data?.messages[0].code !== '0') {
        dispatch(setSavedParametersErrorMessage(data?.messages[0].description));
      }
    }
    setSubmitLoading(false)
  }
  }

  async function openXLSX(pId: number) {
    const submitReportData = {
      UserInformation: profileData[0],
      ParameterCriteria: {
        ParamId: pId,
        OutputFormat: 1,
        OverrideOrgCheck: continueOverride
      }
    }
    setSubmitLoading(true)
    //@ts-ignore
    const { data }: any = await postGetReportSavedParamsData(submitReportData);
    if(data?.messages[0]?.code === '105'
              && data?.messages[0]?.description === 'Orgs are changed'
            ) {
              setSubmitLoading(false);
              if(!displayOverrideOrgCheck){
                 setDisplayOverrideOrgCheck(true);
                 setActionToDo('submit');
                 return false;
              }else if(continueOverride){
                 continueOverride = false;
                setDisplayOverrideOrgCheck(false);
              }
            }else{
  const moreThanSixMb: number = Number(data?.messages[0].code) || 0;
    if (data && data.result) {
      if (moreThanSixMb == 1) {
        dispatch(setSavedParametersErrorMessage(""));
        const linkSource = data.result;
        const fileName = `report.xlsx`;
        setDisplayOverrideOrgCheck(false);
        saveAs(linkSource, fileName);
      } else {
        dispatch(setSavedParametersErrorMessage(""));
        const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data.result}`;
        const fileName = `report.xlsx`;
        setDisplayOverrideOrgCheck(false);
        saveAs(linkSource, fileName);
      }
    } else if (data?.messages[0].code !== '0') {
      dispatch(setSavedParametersErrorMessage(data?.messages[0].description));
    }
    setSubmitLoading(false)
  }
}


  const base64toBlob = (data: string) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);
    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: 'application/pdf' });
  };


  const rowProps = {
    borderRight: "1px",
    borderBottom: "1px",
    borderColor: "gray.100",
    width: "120px",
    fontSize: "xs",
    wordWrap: 'break-word',
  }

  async function modify(row: any, action: string) {
    const submitReportData = {
      UserInformation: profileData[0],
      ParameterCriteria: {
        ParamId: row.PARAM_ID,
        OverrideOrgCheck: continueOverride
      }
    }
    setSubmitLoading(true);
    //@ts-ignore

    let { data }: any = await getCriteriaSavedParamsData(submitReportData);
      if(data?.messages[0]?.code === '105'
        && data?.messages[0]?.description === 'Orgs are changed'
      ) {
        setSubmitLoading(false);
        if(!displayOverrideOrgCheck){
           setDisplayOverrideOrgCheck(true);
           setActionToDo(action);
           return false;
        }else if(continueOverride){
        continueOverride = false;
          setDisplayOverrideOrgCheck(false);
        }
      }else{
        if (data && data.result) {
          if (row.RPT_TYPE_NM === 'Detail Loss') {
        navigate('?page=DetailLoss', {
          state: {
            account_id_selections: data.result.Selections,
            account_id_method: getAccountIdType(data.result.AccountIdType),
            report_name: data.result.ReportName,
            report_heading: data.result.Heading,
            report_type: data.result.OutputFormat === 0 ? 'pdf' : 'excel',
            to_date: data.result.ToDate,
            from_date: data.result.FromDate,
            history_date: data.result.HistoryDate,
            filters: data.result.Limits,
            large_loss_value: data.result.LargeLossValue,
            large_loss_type: getLargeLossType(data.result.LargeLossType),
            groups: data.result.SortGroups,
            param_id: data.result.ParamId,
            saved_params_action: action
          }
        });
      } else if (row.RPT_TYPE_NM === 'Financial Summary') {
        navigate('?page=FinancialSummary', {
          state: {
            account_id_selections: data.result.Selections,
            account_id_method: getAccountIdType(data.result.AccountIdType),
            report_name: data.result.ReportName,
            report_heading: data.result.Heading,
            report_type: data.result.OutputFormat === 0 ? 'pdf' : 'excel',
            to_date: data.result.ToDate,
            from_date: data.result.FromDate,
            history_date: data.result.HistoryDate,
            filters: data.result.Limits,
            ranking: data.result.RankFieldName,
            groups: data.result.SortGroups,
            param_id: data.result.ParamId,
            saved_params_action: action
          }
        });
      } else if (row.RPT_TYPE_NM === 'Detail Changes') {
        navigate('?page=DetailChanges', {
          state: {
            account_id_selections: data.result.Selections,
            account_id_method: getAccountIdType(data.result.AccountIdType),
            report_name: data.result.ReportName,
            report_heading: data.result.Heading,
            report_type: data.result.OutputFormat === 0 ? 'pdf' : 'excel',
            to_date: data.result.ToDate,
            from_date: data.result.FromDate,
            history_date: data.result.HistoryDate,
            history_date_second: data.result.HistoryDateSecond,
            filters: data.result.Limits,
            claim_limit_type: data.result.ClaimLimitDollarType,
            claim_limit_operator: data.result.ClaimLimitOperator,
            claim_limit_amount: data.result.BeginDollarAmount,
            claim_limit_end_amount: data.result.EndDollarAmount,
            groups: data.result.SortGroups,
            param_id: data.result.ParamId,
            saved_params_action: action
          }
        });
      } else if (row.RPT_TYPE_NM === 'Claim Lag') {
        navigate('?page=ClaimLagAnalysis', {
          state: {
            account_id_selections: data.result.Selections,
            account_id_method: getAccountIdType(data.result.AccountIdType),
            report_name: data.result.ReportName,
            report_heading: data.result.Heading,
            report_type: data.result.OutputFormat === 0 ? 'pdf' : 'excel',
            to_date: data.result.ToDate,
            from_date: data.result.FromDate,
            filters: data.result.Limits,
            groups: data.result.SortGroups,
            param_id: data.result.ParamId,
            saved_params_action: action
          }
        });
      } else if (row.RPT_TYPE_NM === 'Ins Retention Summary' || row.RPT_TYPE_NM === 'Ins Retention Detail') {
        navigate('?page=InsuredRetention', {
          state: {
            account_id_selections: data.result.Selections,
            account_id_method: getAccountIdType(data.result.AccountIdType),
            report_name: data.result.ReportName,
            report_heading: data.result.Heading,
            report_selection: data.result.InsuredRetentionType,
            to_date: data.result.ToDate,
            from_date: data.result.FromDate,
            history_date: data.result.HistoryDate,
            filters: data.result.Limits,
            groups: data.result.SortGroups,
            param_id: data.result.ParamId,
            saved_params_action: action
          }
        });
      } else if (row.RPT_TYPE_NM === 'Exp Recap By Grp Mbr' || row.RPT_TYPE_NM === 'Exp Recap By Year' || row.RPT_TYPE_NM === 'Exp Recap By Line' || row.RPT_TYPE_NM === 'Group Member LR') {
        navigate('?page=NationalPrograms', {
          state: {
            account_id_selections: data.result.Selections,
            account_id_method: getAccountIdType(data.result.AccountIdType),
            report_name: data.result.ReportName,
            report_heading: data.result.Heading,
            report_type: data.result.ReportType,
            report_output_type: data.result.OutputFormat === 0 ? 'pdf' : 'excel',
            experience_recap_type: data.result.ExperienceRecapType,
            program_code_selections: data.result.PgmCodeSelections,
            summarize_pgm_codes: data.result.SummarizePgmCodes,
            to_date: data.result.ToDate,
            from_date: data.result.FromDate,
            from_year: data.result.EffYearFrom,
            to_year: data.result.EffYearTo,
            param_id: data.result.ParamId,
            saved_params_action: action
          }
        });
      } else if (row.RPT_TYPE_NM === 'Policy Summary') {
        navigate('?page=PolicySummary', {
          state: {
            account_id_selections: data.result.Selections,
            account_id_method: getAccountIdType(data.result.AccountIdType),
            report_name: data.result.ReportName,
            report_heading: data.result.Heading,
            report_type: data.result.OutputFormat === 0 ? 'pdf' : 'excel',
            from_year: data.result.EffYearFrom,
            to_year: data.result.EffYearTo,
            filters: data.result.Limits,
            param_id: data.result.ParamId,
            saved_params_action: action
          }
        });
      }
    }
    }
  }

  /**
  * Google Analytics Configuration based on User Type.
  * The IsAgent and IsBroker If any of the condition is true then it shoud be AGENT for GA4 Only.
  */
  function getUserType() {
    if (profileData && profileData.length > 0 && profileData[0].Role) {
      if (
        profileData[0].Role.IsAccountId === true ||
        profileData[0].Role.IsAccountLocationId === true
      ) {
        return "CUSTOMER";
      } else if (profileData[0].Role.IsInternalUser === true) {
        return "INTERNAL";
      } else if (profileData[0].Role.IsAgent === true || profileData[0].Role.IsBroker === true) {
        return "AGENT";
      } else {
        return ""
      }
    }
  }

  function getAccountIdType(type: number) {
    if (type === 1) {
      return "Account Number";
    } else if (type === 2) {
      return "Policy Number";
    } else if (type === 3) {
      return "Producer Code";
    } else if (type === 4) {
      return "SAI Number";
    }
  }

  function getLargeLossType(type: number) {
    if (type === 0) {
      return "Incurred";
    } else if (type === 1) {
      return "Paid";
    } else if (type === 2) {
      return "Outstanding";
    }
  }

  function submit(row: any) {
    console.log('row', row);
    /**
    * Google Analytics Configuration
    */
  const analyticsConfig = {
    /* ADD TOP LEVEL TEALIUM ATTRIBUTES HERE */
    action_value: `Submit ${row.RPT_TYPE_NM} Report click`,
    label_value: "SUBMIT",
    event_detail: `${row.RPT_TYPE_NM} Report`,
    event_action: "report run",
    user_id: profileData[0]?.Id,
    login_id: profileData[0]?.Id,
    date: moment(new Date()).format('MM/DD/YYYY'),
    user_type: getUserType(),
    account_identification_type: userAccountIdentification.accountIdentificationMethod,
    capability: `${row.RPT_TYPE_NM}`,
    page_name: `SavedParameters`,
    appid_cd: "loss-report",
    appcd: "loss-report"
  };
  const analyticsProps = getAnalyticsConfig("savedParametersReportSubmission");
  clickAnalytics({
    ...analyticsProps,
    ...analyticsConfig
  }, analytics);

    if (row.RPT_CRIT_FORMAT_CD.trim() === 'PDF') {
      openPDF(row.PARAM_ID);
    } else if (row.RPT_CRIT_FORMAT_CD.trim() === 'XLS') {
      openXLSX(row.PARAM_ID);
    }
  }

  async function deleteParam(row: any) {
    setShowDeleteAlert(false);
    const submitReportData = {
      UserInformation: profileData[0],
      ParameterCriteria: {
        ParamId: row.PARAM_ID,
        EventType: 3,
        OverrideOrgCheck: false
      }
    };
    setSubmitLoading(true)
    const { data }: any = await saveParamaters(submitReportData);
    if (data && data.result) {
      setSubmitLoading(false);
      setRefresh(true);
    }
  }

  async function renameRow(e: any, row: any) {
    const submitReportData = {
      LossesCriteria: {
        ReportName: e.trim(),
      },
      UserInformation: profileData[0],
      ParameterCriteria: {
        ParamId: row.PARAM_ID,
        EventType: 4,
        OverrideOrgCheck: false
      }
    };
    const { data }: any = await saveParamaters(submitReportData);
    if (data && data.result) {
      return data.result;
    } else if (data?.messages[0].code !== '0') {
      dispatch(setSavedParametersErrorMessage(data?.messages[0].description));
    }
  }


  return (
    <div ref={msgRef} className='saved-parameter-search-results-main-div'
      onClick={e => {
        dispatch(setSavedParametersSuccessfulRenameMessage(""));
        setShowDeletedSuccessMsg(false);
        dispatch(setSavedParametersErrorMessage(""));
        if (!isEditing) {
          dispatch(setSavedParametersEmptyMessage(""));
          dispatch(setSavedParametersDuplicateMessage(""));
        } else {
          setIsEditing(false);
        }
      }}
    >
      {showDeleteAlert && (
        <div className='tds-alert--warning tds-alert--dismissible saved-parameter-search-results-delete-div' tabIndex={-1} style={{ width: '40rem' }}>
          <h2 className='tds-alert__title' id='dismissible-warning-alert'>
            DELETE REPORT
          </h2>
          <p style={{ marginTop: '0.5rem' }}>Are you sure you want to remove this report from your library?</p>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between center', margin: '0 auto' }}>
            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
              <button onClick={() => checkedRow === undefined ? "" : deleteParam(searchResults[checkedRow])} className='tds-button--secondary tds-button--small' type='button' style={{ height: '2rem', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                DELETE
              </button>
              <button onClick={() => setShowDeleteAlert(false)} className='tds-button--tertiary tds-button--small' type='button' style={{ height: '2rem', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                CANCEL
              </button>
            </Box>
          </div>

          <button onClick={() => setShowDeleteAlert(false)} aria-label='Dismiss alert.' className='tds-alert__dismiss' data-focus='#my-alert-info-dismissible-trigger'>
            <SearchResultsDeleteAlertCloseIcon alt='Close Alert' />
          </button>
        </div>
      )}
      <div
        id="invalid-report-name-message"
        aria-atomic="true"
        className="tds-alert--warning"
        role="alert"
        hidden={emptyMsg !== "true"}
      >
        <p style={{ fontSize: "14px" }}>
          Please enter Report Name
        </p>
        <button
          onClick={() => {
            dispatch(setSavedParametersEmptyMessage(""));
          }}
          aria-label="Dismiss alert."
          className="tds-alert__dismiss"
        >
          <SearchResultsDeleteAlertCloseIcon alt="Close Alert" />
        </button>
      </div>


      <div
                id="rename-success-message"
                aria-atomic="true"
                className="tds-alert--success"
                role="alert"
                hidden={_displayRename!=="true"}              >
                <p style={{ fontSize: "14px" }}>
                Report Name has been updated
                </p>
                <button
                  onClick={() => {
                    dispatch(setSavedParametersSuccessfulRenameMessage(""));
                  }}
                  aria-label="Dismiss alert."
                  className="tds-alert__dismiss"
                >
                  <SearchResultsDeleteAlertCloseIcon alt="Close Alert" />
                </button>
              </div>
              <div
                id="rename-success-message"
                aria-atomic="true"
                className="tds-alert--warning"
                role="alert"
                hidden={!displayOverrideOrgCheck}
              >
                <div className="tds-alert__title">
                  Organization Structure Changed
                </div>
                <p style={{ fontSize: "14px", marginRight: '5rem' }}>
                Warning:  This saved Parameter contains custom organization fields.  Since the creation of this saved parameter, an organization restructure has occurred.  Please review and modify any outdated organization fields utilized to avoid invalid results.
                </p>
                <button style={{marginTop:'1.5rem', font:'12px', fontStyle:'BattersonSans', border: '1px solid #E01719', color: '#E01719'}}
                  onClick={() => {
                    continueOverride = true;
                    if(checkedRow!==undefined){
                      actionToDo==='submit'?submit(searchResults[checkedRow]):modify(searchResults[checkedRow], actionToDo);
                  }
                  }}
                  aria-label="Dismiss alert."
                  className="tds-alert__dismiss"
                >
                  CONTINUE
                </button>
                <button style={{marginTop: '4.5rem', marginRight:'.5rem', color: '#007395', font:'12px', fontStyle:'BattersonSans', fontWeight: 'normal'}}
                  onClick={() => {
                    continueOverride = false;
                    setDisplayOverrideOrgCheck(false);
                  }}
                  aria-label="Dismiss alert."
                  className="tds-alert__dismiss"
                >
                  CANCEL
                </button>

                <button style={{marginTop:'-.75rem'}} 
                onClick={() => {
                    continueOverride = false;
                    setDisplayOverrideOrgCheck(false);
                  }} aria-label='Dismiss alert.' className='tds-alert__dismiss' data-focus='#my-alert-info-dismissible-trigger'>
            <SearchResultsDeleteAlertCloseIcon alt='Close Alert' />
          </button>
              </div>
      <div
        id="duplicate-report-name-message"
        aria-atomic="true"
        className="tds-alert--warning"
        role="alert"
        hidden={duplicateMsg !== "true"}
      >
        <p style={{ fontSize: "14px" }}>
          Report Name already exists
        </p>
        <button
          onClick={() => {
            dispatch(setSavedParametersDuplicateMessage(""));
          }}
          aria-label="Dismiss alert."
          className="tds-alert__dismiss"
        >
          <SearchResultsDeleteAlertCloseIcon alt="Close Alert" />
        </button>
      </div>

      {((!searchResults || searchResults.length === 0) && !isLoading) &&
        <Text fontSize='2xl'>No Saved Parameters found</Text>
      }

      {searchResults && searchResults.length > 0 &&
        <TableContainer
          whiteSpace="break-spaces"
        >
          <Table
            size="sm"
            variant='unstyled'
            layout="fixed"
          >
            <Thead
              aria-label='search-results'
              mt={-4}
              pt={4}
              position="sticky"
              top={0}
              backgroundColor="white"
              boxShadow="inset 0 -2px 0 #000000"
              zIndex={1}
              display="table"
              width="100%"
            >
              <Tr>
                <Td colSpan={6}>
                  <Text as="h3" textStyle="h3">
                    Search Results
                  </Text>
                </Td>
              </Tr>
              <Tr>
                <Th
                  aria-label='#'
                  width="24px"
                ></Th>
                <Th
                  width="100px"
                >
                  ACCOUNT NAME
                </Th>
                <Th
                  width="180px"
                >
                  REPORT NAME
                </Th>
                <Th width="120px">
                  REPORT TYPE
                </Th>
                <Th width="150px">
                  ACCOUNT IDENTIFICATION
                </Th>
                <Th
                  width="60px"
                >
                  PGM CODE
                </Th>
              </Tr>
            </Thead>
            <Tbody
              maxHeight="400px"
              display="block"
              overflowY="auto"
              mt={4}
            >
              {
                searchResults.sort((a, b) => a.PARAM_ID - b.PARAM_ID).map((record, index) => {
                  const { RPT_PGMCODE_TXT, RPT_ACCT_NM, RPT_TYPE_NM, RPT_SELECTION_TXT, RPT_PGMCODE_TXT1 } = record;
                  const accountIdentificationNumbers = RPT_SELECTION_TXT.split(",")?.map((string: string) => string?.replace(/\s/g, ''))
                  const splitPGMCodes = RPT_PGMCODE_TXT?.split(",");
                  const firstPgmCode = RPT_PGMCODE_TXT1 === "Y" && splitPGMCodes && !!splitPGMCodes[0] ? RPT_PGMCODE_TXT : "N/A"
                  return (
                    <Tr
                      display="table"
                      width="100%"
                      style={{ tableLayout: 'fixed' }}
                      key={index}
                    >
                      <Td {...rowProps} alignContent="center" padding="5px" width="24px" maxWidth="24px">
                        <input type="checkbox"
                          aria-label='select-checkbox'
                          data-testid='select-row'
                          checked={checkedRow === index}
                          onChange={(e) => {
                            if (checkedRow === index) {
                              setCheckedRow(undefined)
                            } else {
                              setCheckedRow(index)
                            }
                          }}
                        />
                      </Td>
                      <Td {...rowProps} width="100px" maxWidth="100px" >
                        {RPT_ACCT_NM}
                      </Td>
                      <Td {...rowProps} p={0} width="180px" maxWidth="180px"  >
                        <RecordRow record={record} setIsEditing={setIsEditing} setScroll={setScroll} />
                      </Td>
                      <Td {...rowProps} isTruncated width="120px" maxWidth="120px" whiteSpace="break-spaces">
                        {RPT_TYPE_NM}
                      </Td>
                      <Td {...rowProps} isTruncated width="150px" maxWidth="150px">
                        <Tooltip fontSize={12} label={RPT_SELECTION_TXT.includes(",") ? RPT_SELECTION_TXT?.split(",").join(", ") : null}>
                          <Box overflow="hidden" textOverflow="ellipsis">
                            {`${accountIdentificationNumbers[0]}${accountIdentificationNumbers[1] ? "," : ""}${accountIdentificationNumbers[1] ?? ''}${accountIdentificationNumbers[2] ? "," : ""}${accountIdentificationNumbers[2] ?? ''}`}
                            {accountIdentificationNumbers[3] && <br />}
                            {`${accountIdentificationNumbers[3] ?? ''}${accountIdentificationNumbers[4] ? "," : ""}${accountIdentificationNumbers[4] ?? ''}`}
                          </Box>
                        </Tooltip>
                      </Td>
                      <Td {...rowProps} isTruncated maxWidth="60px">
                        <Tooltip fontSize={12} label={RPT_PGMCODE_TXT1 === "Y" ? (RPT_PGMCODE_TXT.length > 32 ? <Text>{RPT_PGMCODE_TXT.substring(0, 32)}<br />{RPT_PGMCODE_TXT.substring(32, RPT_PGMCODE_TXT.length)}</Text> : RPT_PGMCODE_TXT) : null}>
                          <Box overflow="hidden" textOverflow="ellipsis">
                            {firstPgmCode}
                          </Box>
                        </Tooltip>
                      </Td>
                    </Tr>
                  )
                })
              }
            </Tbody>
          </Table>
        </TableContainer>
      }
      {submitLoading && <TdsLoadingSpinner data-testid='spinner' label="Processing" size="xxx-large" label-displayed overlay centered></TdsLoadingSpinner>}
      {searchResults && searchResults.length > 0 &&
        <div className='saved-parameters-search-results-div'>
          <button disabled={checkedRow === undefined}
            className='tds-button--secondary tds-button--small saved-parameters-search-btn'
            data-testid='submit-button'
            style={{ height: '2rem', marginTop: '12px'}}
            type='button'
            onClick={(e) => { checkedRow !== undefined ? submit(searchResults[checkedRow]) : submit("") }}>
            SUBMIT
          </button>
          <button
            onClick={(e) => { checkedRow !== undefined ? modify(searchResults[checkedRow], 'M') : modify("", 'M') }}
            disabled={checkedRow === undefined}
            className='tds-button--secondary tds-button--small homeform-act-search-btn'
            data-testid='modify-button'
            style={{ height: '2rem' }}
            type='button'>
            MODIFY
          </button>
          <button
            onClick={(e) => { checkedRow !== undefined ? modify(searchResults[checkedRow], 'C') : modify("", 'C') }}
            disabled={checkedRow === undefined}
            className='tds-button--secondary tds-button--small homeform-act-search-btn'
            data-testid='copy-button'
            style={{ height: '2rem' }}
            type='button'>
            COPY
          </button>
          <button disabled={checkedRow === undefined}
            onClick={(e) => { setShowDeleteAlert(checkedRow !== undefined) }}
            className='tds-button--secondary tds-button--small homeform-act-search-btn' 
            data-testid='delete-button'
            style={{ height: '2rem' }} 
            type='button'>
            DELETE
          </button>
        </div>
      }

      {
        showRenameForm && (
          <div className='saved-parameter-search-results-rename-div'>
            <div style={{ width: '56.5%' }}>
              <TdsField>
                <input style={{ height: '2rem' }} type='text' aria-label='policy number' />
              </TdsField>
            </div>

            <button style={{ width: '18%', height: '2rem' }} className='tds-button--primary tds-button--small' type='button'>
              UPDATE
            </button>
            <button onClick={() => setShowRenameForm(false)} style={{ width: '18%', height: '2rem' }} className='tds-button--tertiary tds-button--small' type='button'>
              CANCEL
            </button>
          </div>
        )
      }
    </div >
  );
};
export default SavedParametersSearchResults;
