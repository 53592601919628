import { BLANK_GROUP } from "../../common/constants";
import { GroupOption, Operator } from "../../common/types";

export type DowndownOptions = {
  _value: string;
  _text: string;
  _Type: string;
  _current: string;
  _history: string;
  _unquoted: string;
};

export type DowndownLookUp = {
  _value: string;
  _col: string;
  _alias: string;
  _current: string;
};

export type DowndownData = {
  option: DowndownOptions[];
  lookup: DowndownLookUp[];
};

export type WCFilter = {
  result: FilterLookupResult;
  LOB: String;
};
export type FilterLookupResult = {
  Code: string;
  Text: string;
  STATE_PRIORITY: string;
  LINE_TYP_CD_ID: string;
  selected: boolean;
};
export type FilterLookupMessages = {};

export type FilterLookupOptions = {
  messages: FilterLookupMessages[];
  result: FilterLookupResult;
};

export const limitOperatorOptions = [
  { value: "EQ", label: "Equal To" },
  { value: "NE", label: "Not Equal To" },
  { value: "LT", label: "Less Than" },
  { value: "GT", label: "Greater Than" },
  { value: "Contains", label: "Contains" },
  { value: "Omits", label: "Omits" },
  { value: "StartsWith", label: "Starts With" },
];

export enum largeLimitOptions {
  Incurred = "Incurred",
  Paid = "Paid",
  Outstanding = "Outstanding",
}

export enum rankingOptions {
  NoRanking = "No Ranking",
  ClaimCount = "Claim Count",
  IncurredDollars = "Incurred Dollars",
}

export enum dollarTypeOptions {
  Incurred = "Incurred",
  Paid = "Paid",
  Either = "Either",
}

export const dollarTypeOperatorOptions = [
  { value: "EQ", label: "Equal To" },
  { value: "GT", label: "Greater Than" },
  { value: "LT", label: "Less Than" },
  { value: "RG", label: "Range" },
];

export const nationalProgramReportOptions = [
  {
    value: "ExperienceRecap|ByGroupMember",
    label: "Experience Recap By Group Member",
  },
  { value: "ExperienceRecap|ByYear", label: "Experience Recap By Eff Year" },
  { value: "ExperienceRecap|ByLine", label: "Experience Recap By Line" },
  { value: "GroupMemberLossRun", label: "Group Member Loss Run" },
];

export function isValidDate(date: String) {
  let d = new Date(JSON.stringify(date));
  let result = d && Object.prototype.toString.call(d) === "[object Date]"; //&& !isNaN(d);
  if (!result) {
    return result;
  }
  return d.getFullYear() >= 1900 && d.getFullYear() <= 2100;
}

export function validDateFormat(val: String) {
  const regex = new RegExp("[0-9][0-9]/[0-9][0-9]/[0-9][0-9][0-9][0-9]");
  let result = regex.test(JSON.stringify(val));
  return result;
}

export function validYear(val: String) {
  const regex = new RegExp("[0-9][0-9][0-9][0-9]");
  let result = regex.test(JSON.stringify(val));
  return result;
}

export function yearInRange(val: String) {
  return Number(val) >= 1900 && Number(val) <= 2100;
}

export const delay = (time: number) => {
  return new Promise((res) => {
    setTimeout(res, time);
  });
};

export function getCurrentDateMinusTwo() {
  let date = new Date();
  date.setDate(date.getDate() - 2);
  return date.toLocaleDateString("en-US", {
    // you can use undefined as first argument
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
}

export function getHistoryDateOld(hDate: String) {
  if (hDate && hDate.length === 7) {
    let month = Number.parseInt(hDate.substring(0, 2));
    let year = Number.parseInt(hDate.substring(3, 7));
    let endD = new Date(year, month, 0);
    console.log("date is" + endD.toString());
    return "" + endD;
  }
}

export function getHistoryDate(hDate: String) {
  if (hDate && hDate.length === 7) {
    let month = Number.parseInt(hDate.substring(0, 2));
    let year = Number.parseInt(hDate.substring(3, 7));
    let endD = new Date(year, month, 0);
    return "" + endD.toISOString().substring(0, 10);
  }
}

export function getHistoryDateSavedParams(hDate: String) {
  if (hDate && hDate.length === 19 && !hDate.startsWith("0001")) {
    let month = Number.parseInt(hDate.substring(5, 8));
    let year = Number.parseInt(hDate.substring(0, 4));
    let mthStr = "0" + month;
    return mthStr.substring(mthStr.length - 2, mthStr.length) + "/" + year;
  }
}

export function getOperator(oper: String): Operator {
  if (oper === "Equal To") return "EQ";
  else if (oper === "Not Equal To") return "NE";
  else if (oper === "Less Than") return "LT";
  else if (oper === "Greater Than") return "GT";
  else if (oper === "Contains") return "Contains";
  else if (oper === "Omits") return "Omits";
  else if (oper === "Starts With") return "StartsWith";
  else return "-1";
}

export function reverseGetOperator(oper: Operator): String {
  if (oper === "EQ") return "Equal To";
  else if (oper === "NE") return "Not Equal To";
  else if (oper === "LT") return "Less Than";
  else if (oper === "GT") return "Greater Than";
  else if (oper === "Contains") return "Contains";
  else if (oper === "Omits") return "Omits";
  else if (oper === "StartsWith") return "Starts With";
  else return "Equal To";
}

export function getOper(oper: Operator): string {
  if (oper === "EQ") return "Equal To";
  else if (oper === "NE") return "Not Equal To";
  else if (oper === "LT") return "Less Than";
  else if (oper === "GT") return "Greater Than";
  else if (oper === "Contains") return "Contains";
  else if (oper === "Omits") return "Omits";
  else if (oper === "StartsWith") return "Starts With";
  else return "-1";
}


export function getLargeLossType(selection: String) {
  if (selection === "Incurred") return 0;
  else if (selection === "Paid") return 1;
  else if (selection === "Outstanding") return 2;
  else if (selection === "Either") return 3;
  else return 4;
}

export function getLargeLossValue(selection: Number) {
  if (selection === 0) return "Incurred";
  else if (selection === 1) return "Paid";
  else if (selection === 2) return "Outstanding";
  else if (selection === 3) return "Either";
  else return "";
}


export function getGroups(groups: any[]) {
  let rtnGroups: GroupOption[] = [];
  if (groups) {
    groups.slice().reverse().forEach((group, index) => {
      console.log("🚀 ~ groups.slice ~ group:", group)
      rtnGroups.push({
        field: group.FieldName,
        label: group.Label,
        hasSubtotal: group.Subtotal,
        hasPageBreak: group.PageBreak,
        disableCheckboxes: (group.FieldName === "OC_CLM_NBR" ||
          group.FieldName === "CLM_NBR"),
        groupKey: index + 1,
      })
    })
  }
  return rtnGroups.length === 0 ? [BLANK_GROUP] : rtnGroups;
}
