import { ReactComponent as QuestionCircle118 } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/question-circle-1-18.svg';
import { Box, Button, FormControl, FormLabel, Input, Stack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import AgentsSearchResults from './AgentsAccountSearchResults';
import { AccIdentification } from './agentAccount';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

const AgentAccountSearch = () => {

  const [searchString, setSearchString] = useState<string>("");
  const [showAgentsSearchResultsModal, setShowAgentsSearchResultsModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const { isAgentUser } = useAuth();
  const userAccountIdentification: AccIdentification = useSelector((state: RootState) => state.agentAccount.accountIdentification);
  const { accountIdentificationMethod, accountIdentificationSelections } = userAccountIdentification;
  const searchDisabled = accountIdentificationSelections.length > 0 && (accountIdentificationMethod === "Policy Number" || accountIdentificationMethod === "Producer Code")

  const setSearchStringValue = () => {
    setIsLoading(true)
    setShowAgentsSearchResultsModal(true);
  }

  return <Box mb={4}>
    {(!isAgentUser) ? <div /> : (<Stack>
      <Text as="h3" textStyle="h3">
        Account Search &nbsp;
      <div id='tooltip-example-top' className='docs-tooltip-sample' style={{ display: 'inline' }}>
        <span data-toggle='tooltip' role='button' tabIndex={0} className='tds-tooltip__container' aria-label='more information'>
          <QuestionCircle118 className='tds-icon-18' data-toggle='tooltip' color='#007395' />
            <div role='tooltip' className='tds-tooltip' data-position='bottom' style={{ height: '80px' }}>
              <p style={{ fontWeight: '300', textAlign: 'left', lineHeight: '1.6' }}>
                Leave the search window blank to receive a complete listing of your accounts. <br></br>
                Or, search accounts by entering beginning characters.
              </p>
            </div>
         </span>
      </div>
      </Text>
  
    <FormControl>
        <FormLabel textStyle="label">
          Name begins with
        </FormLabel>
        <Stack direction={['column', 'column', 'row']}>
          <Input data-testid="account-search-input" maxLength={9} maxW={['100%', '100%', 250]} size="sm" onChange={(e) => setSearchString(e.target.value)} />
          <Button
            isLoading={isLoading}
            isDisabled={searchDisabled}
            size="md"
            onClick={setSearchStringValue}
            variant="outline"
            data-testid="account-search-button">
            Search
          </Button>
        </Stack>
      </FormControl>
      {showAgentsSearchResultsModal && <AgentsSearchResults setIsLoading={setIsLoading} searchString={searchString} isOpen={showAgentsSearchResultsModal} setIsOpen={setShowAgentsSearchResultsModal} />}
    </Stack>)}
  </Box>
};
export default AgentAccountSearch;