import { apiSlice } from '../../app/api/apiSlice';
import { API, REQ_TYPE } from '../../common/constants';
import { UserProfile } from './profile';

export const profileApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query<UserProfile, void>({
      query: () => {
        return {
          method: REQ_TYPE.POST,
          url: API.PROFILE,
        };
      },
      transformResponse: (response: any) => response.result,
    }),
    }),
});

export const { useGetProfileQuery } = profileApiSlice;
