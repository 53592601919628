import { FilterOption, GroupOption } from "./types";

export const HTTP = {
  CONTENT_TYPE: 'content-type',
  APP_JSON: 'application/json',
  SUCCESS_CODE: '200',
  API_SUCCESS_CODE: '0',
  STATUS_200: 200,
  STATUS_201: 201,
  STATUS_MESSAGE: 'Success',
};

export const REQ_TYPE = {
  DELETE: 'DELETE',
  PUT: 'PUT',
  POST: 'POST',
  GET: 'GET',
};

export const RESPONSES = {
  ERROR_404: {
    error: {
      statusCode: '404',
      message: '404 - Response returned an error',
    },
  },
  ERROR_504: {
    error: {
      statusCode: '504',
      message: '504 - Request Error',
    },
  },
};

export const API = {
  PROFILE: '/userProfile',
  //PROFILE: '/profile',
  AGENT_LIST: '/agent-list',
  ACCOUNT_LIST: '/accountNameList',
  DROP_DOWN_LIST: '/accountFields',
  CLAIM_ACTIVITY: '/claimActivity',
  CLM_ACT_DRILLDOWN: '/claimActivityDrilldown',
  SAVED_PARAMS: '/getSavedParams',
  GET_REPORTS_SAVED_PARAMS: '/getReportSavedParam',
  GET_CRITERIA_SAVED_PARAMS: '/getCriteria',
  FILTER_LOOKUP: '/getLookupValues',
  ORG_LEVEL_DATA: '/getOrgData',
  UPDATE_PARAM: '/updateParam',
  SUBMIT_REPORT: '/getReport',
  SUBMIT_ACCOUNT_REPORT: '/getAccountListingReport',
  PREFILL_DATA: '/preFilledAccountCriteria',
  GET_CLEARINGHOUE_IDS: '/clearinghouseID',
  GET_EMAIL_SUBSCRIPTIONS: '/getEmailSubscriptionStatus',
  SUBSCRIBE_EMAIL_ALERT: '/subscribeEmailAlert',
  UNSUBSCRIBE_EMAIL_ALERT: '/unSubscribeEmailAlert',
  GET_ALL_SAI_ALERTS: '/GetSAIInformation',
  SAVE_SAI_ALERT: '/SaveInternalUserSAI',
  DELETE_SAI_ALERT: '/DeleteInternalUserSAI',
};

export const MOCK_API_BASE_URL = '/mock-api';

export const LOAD_POLICY = 'LOAD_PROFILE';

export const LOAD_AGENCY_LIST = 'LOAD_AGENT_LIST';


export const TIMEOUT = {
  IDLE: 20 * 60 * 1000,
  WARNING: 2 * 60 * 1000,
};

export const MAX_RESULTS_WARNING = 'WARNING: Your search results exceed the maximum of 500 accounts that can be displayed.  If the account you are looking for is not displayed, please refine your search';

export const MIXED_RESULTS_WARNING = 'Account Number(s) cannot be selected in combination with SAI Number(s)';

export enum accountIdentificationOptions {
  PolicyNumber = 'Policy Number',
  AccountNumber = 'Account Number',
  SAINumber = 'SAI Number',
  ProducerCode = 'Producer Code'
};

export const BLANK_FILTER: FilterOption = { filterKey: 1, field: '', operator: '', value: '', dbValue: '', isDirty: false, operatorError: false, dateInvalidError: false, dateFormatError: false, yearFormatError: false, invalidDateOperatorError: false};
export const BLANK_GROUP: GroupOption = { field: '', label: '', hasPageBreak: false, hasSubtotal: false };

export const DEFAULT_USER = {
  FirstAccount: null,
  EmailAddress: 'KUZNANSK@travelers.com',
  UserFullName: null,
  FirstAccountLocations: null,
  Username: 'KUZNANSK',
  SelInfoName: null,
  IsAccountId: false,
  IsAccountLocationId: false,
  IsBroker: false,
  IsColaaBroker: false,
  RmisId: 'EXTERNAL',
  PortalName: null,
  NickName: 'KUZNANSK',
  EmploymentId: null,
  IsInternalUser: true,
  IsAuthorized: true,
  BrokerCodes: null,
}

export const DEFAULT_SAVED_PARAMETERS_USER = {
  id: "DTRAN",
  email: "MMAYOTTE@travelers.com",
  message: "validated",
  FirstName: "Matt",
  LastName: "Mayotte",
  UserEmail: "MMAYOTTE@travelers.com",
  Role: {
    IsPilotUser: false,
    IsLossReportsUser: true,
    IsLossReportsUserNationalPrograms: false,
    IsTracerUser: true,
    IsAccountId: false,
    IsAccountLocationId: false,
    IsColaaBroker: false,
    IsBroker: false,
    IsAuthorized: false,
    IsInternalUser: true,
    RoleCodes: ["csmp2", "ecrepv", "ecrosha", "ecrtrc", "elrs1"],
  },
}


export const DEFAULT_CLAIM_USER = {
  ...DEFAULT_USER, ...{
    IsAccountId: true,
    IsAccountLocationId: true,
    IsBroker: true,
    IsColaaBroker: false,
  }
}

// export const ROLE_USER = {
//   id: "mmayotte",
//   email: "MMAYOTTE@travelers.com",
//   message: "validated",
//   FirstName: "Matt",
//   LastName: "Mayotte",
//   UserEmail: "MMAYOTTE@travelers.com",
//   Role: {
//     IsEcarmaUser: false,
//     IsPilotUser: false,
//     IsLossReportsUser: true,
//     IsLossReportsUserNationalPrograms: false,
//     IsTracerUser: true,
//     IsAccountId: false,
//     IsAccountLocationId: false,
//     IsColaaBroker: false,
//     IsBroker: false,
//     IsAuthorized: false,
//     IsInternalUser: true,
//     BrokerCodes: null,
//     EmploymentId: null,
//     EcarmaRole: "",
//     LossreportUserRole: "elrs1",
//     LossreportNationalProgramUserRole: "",
//     TracerRole: "ecrtrc",
//     PilotRole: ""
//   }
// }

export const DEFAULT_FIELD_OPTION_EXCLUSIONS = [
  "Account Number",
  "SAI Number",
  "Policy Form Code"
]

export const NON_PRODUCER_FIELD_OPTIONS = [
  'Claim Number Other',
  'Policy Number Other',
  'Location Code',
]