import {
  Box,
  BoxProps,
  Button,
  Checkbox,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import saveAs from "file-saver";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AccountRecord, AccountSearchRequest } from "../../../common/types";
import { useGetAgentListQuery } from "../../../features/agent/agentApiSlice";
import { useSubmitListingReportMutation } from "../../../features/submitReport/submitReportApiSlice";
import useAuth from "../../../hooks/useAuth";
import { RootState } from "../../../store";
import { Loader } from "../Common/Loader";
import { WarningBanner, WarningType } from "./WarningBanner";
import { AccIdentification, AgentAccount } from "./agentAccount";
import { updateAccountIdentification } from "./agentAccountSlice";

type AgentsAccountSearchResultsProps = {
  searchString: string;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const AccountTable = ({
  agentAccountList,
  handleCheckbox,
  height = 400,
}: {
  agentAccountList: AgentAccount[];
  handleCheckbox: Function;
  height?: number;
}) => {

  return (
    <TableContainer h={height} overflowX="unset" overflowY="scroll">
      <Table
        data-testid="account-search-results-table"
        size="sm"
        variant="simple"
        style={{ borderCollapse: "separate" }}
      >
        <Thead
          borderBottomStyle="solid"
          borderBottomWidth={2}
          borderBottomColor="black"
          position="sticky"
          top={0}
          zIndex="docked"
          bgColor="white"
          m={0}
        >
          <Tr>
            <Th borderBottom=".1rem solid black"></Th>
            <Th borderBottom=".1rem solid black">ACCOUNT NAME</Th>
            <Th borderBottom=".1rem solid black">ACCOUNT IDENTIFIER</Th>
          </Tr>
        </Thead>
        <Tbody data-testid="account-search-results-table-body">
          {agentAccountList.length === 0 && (
            <Tr>
              <Td>
                <Text
                  margin={8}
                  w="100%"
                  alignContent="center"
                  as="h4"
                  textStyle="h4"
                >
                  No Results Found
                </Text>
              </Td>
            </Tr>
          )}
          {agentAccountList.map((record, index) => {
            const { AccountName, AccountIdentifier, isAdded, isSAI } = record;
            return (
              <Tr key={`id-${index}`}>
                <Td maxW={20}>
                  <Checkbox
                    key={AccountName}
                    aria-label="select account listing"
                    onChange={(e) => handleCheckbox(e, record)}
                    isChecked={isAdded}
                  />
                </Td>
                <Td fontSize="sm">
                  {AccountName}
                </Td>
                <Td fontSize="sm">
                  {AccountIdentifier} {isSAI === "N" && ` *`}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const AgentsAccountSearchResults = ({
  isOpen,
  setIsOpen,
  setIsLoading,
  searchString,
}: AgentsAccountSearchResultsProps) => {
  const { profileData } = useAuth();
  const userAccountIdentification: AccIdentification = useSelector(
    (state: RootState) => state.agentAccount.accountIdentification
  );
  const { accountIdentificationSelections, accountIdentificationMethod } =
    userAccountIdentification;
  const dispatch = useDispatch();
  const [agentAccountList, setAgentAccountList] = useState<AgentAccount[]>();
  const [hasMixedResultsWarning, setHasMixedResultsWarning] = useState(false);
  const [showMaxWarning, setShowMaxWarning] = useState(true);
  const ref = useRef<HTMLInputElement>(null);

  const accountParams: AccountSearchRequest = {
    AccountCriteria: { AccountSearch: searchString },
    UserInformation: profileData[0],
  };

  const regularAccountParams: AccountSearchRequest = {
    ...accountParams,
    ...{
      AccountCriteria: {
        AccountSearch: "",
        FullName: profileData[0].FirstName,
      },
    },
  };

  const [postReportData, { isLoading: isPDFLoading }] =
    useSubmitListingReportMutation();

  const {
    data,
    isLoading: isAccountListLoading,
    isSuccess: isAccountListSuccess,
    isError: isAccountListError,
  }: {
    data?: { result?: AccountRecord[] };
    isLoading: Boolean;
    isSuccess: Boolean;
    isError: Boolean;
  } = useGetAgentListQuery(accountParams);

  const accountListData = data?.result;

  const downloadPDF = async () => {
    const { data }: any = await postReportData(regularAccountParams);
    const moreThanSixMb: number = Number(data?.messages[0].code) || 0;
    if (data && data.result) {
      if (moreThanSixMb == 1) {
        console.log('Report file size is more than 6 MB.', data.result);
        const pdfURL: string = data.result;
        saveAs(pdfURL, 'completeAccountListing.pdf');
      } else {
        const linkSource = `data:application/pdf;charset=utf-8;base64,${data.result}`;
        const fileName = `report.pdf`;
        saveAs(linkSource, fileName);
      }
    }
  };

  const serverAgentAccounts = (accountListData || []).map((record) => {
    const { ACCT_NM, ACCT_NBR, SAI_IND } = record;
    let agentAccount = new AgentAccount(ACCT_NM, ACCT_NBR, SAI_IND);
    if (accountIdentificationSelections.find((el: any) => el === ACCT_NBR)) {
      agentAccount.isAdded = true;
    }
    return agentAccount;
  });

  function onlyUnique(value: string, index: number, array: string[]) {
    return array.indexOf(value) === index;
  }

  const allCheckedAccounts = agentAccountList?.filter((d) => d.isAdded) ?? [];
  const allUncheckedAccounts =
    agentAccountList
      ?.filter((d) => !d.isAdded)
      .map((_acc) => _acc.AccountIdentifier) ?? [];
  const storageMinusLocal = accountIdentificationSelections.filter(
    (acc) => !allUncheckedAccounts.includes(acc)
  );
  const uniqueCheckedAccounts = [
    ...allCheckedAccounts.map((a) => a.AccountIdentifier),
    ...storageMinusLocal,
  ].filter(onlyUnique);
  const allAccountTypes = [...new Set(allCheckedAccounts?.map((d) => d.isSAI))];
  const totalSelected = uniqueCheckedAccounts.length;

  useEffect(() => {
    if (!isAccountListLoading && !agentAccountList && serverAgentAccounts) {
      setAgentAccountList(serverAgentAccounts);
      setIsLoading(false);
    }
  }, [accountListData, agentAccountList, isAccountListLoading]);

  const addAccountUsers = () => {
    if (allAccountTypes.length > 1) return;
    setHasMixedResultsWarning(false);
    const isSAI = allAccountTypes[0] === "Y";
    if (
      allAccountTypes.length !== 0 ||
      accountIdentificationSelections.length > 0
    ) {
      dispatch(
        updateAccountIdentification({
          ...userAccountIdentification,
          accountIdentificationSelections: uniqueCheckedAccounts,
          accountIdentificationMethod:
            allAccountTypes.length === 0
              ? accountIdentificationMethod
              : isSAI
                ? "SAI Number"
                : "Account Number",
        })
      );
    }
    setIsOpen(false);
  };

  const scrollToElement = () => {
    ref?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleCheckbox = (e: any, record: any) => {
    const checkedValue = e.target.checked as boolean;
    if (allAccountTypes.length > 1) return;
    const _isSAI = allAccountTypes[0];
    if (totalSelected >= 4 && checkedValue) {
      scrollToElement();
      return;
    }
    const isMismatch = accountIdentificationSelections.length > 0 &&
      ((record.isSAI === "N" &&
        accountIdentificationMethod === "SAI Number") || (record.isSAI === "Y" && accountIdentificationMethod === "Account Number"));

    if (isMismatch || (_isSAI && record.isSAI !== _isSAI)) {
      setHasMixedResultsWarning(true);
      record.isAdded = false;
      e.target.setChecked = false; //how to uncheck a box from here?
      scrollToElement();
      return;
    }
    setHasMixedResultsWarning(false);

    const _accounts = agentAccountList?.map((account) => {
      return account.AccountIdentifier === record.AccountIdentifier
        ? { ...account, isAdded: checkedValue }
        : account;
    });
    setAgentAccountList(_accounts);
  };
  const hasMaxWarning = agentAccountList && agentAccountList?.length >= 500;
  const hasMaxAccountsWarning = totalSelected >= 4;
  const tableHeight =
    430 -
    (hasMaxWarning && showMaxWarning ? 120 : 0) -
    (hasMaxAccountsWarning ? 120 : 0) -
    (hasMixedResultsWarning ? 120 : 0);
  return (
    <Modal
      closeOnOverlayClick={false}
      motionPreset="slideInBottom"
      onClose={() => {
        setIsOpen(false);
        setIsLoading(false);
      }}
      isOpen={isOpen}
      size="xl"
      scrollBehavior="inside"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textStyle="h1" textAlign="center">
          Account Listing
          <p style={{ fontSize: 12, marginBottom: 0 }}>
            * Denotes Account Number, otherwise SAI Number is shown
          </p>
        </ModalHeader>
        <ModalCloseButton
          border="none"
          cursor="pointer"
          aria-label="Dismiss Claim Activity e-Alerts"
        />
        <Divider />
        <ModalBody overflowY="hidden">
          <Box>
            <Box bgColor="white">
              {hasMixedResultsWarning && (
                <WarningBanner warningType={WarningType.mixedResults} />
              )}
              {hasMaxAccountsWarning && (
                <WarningBanner warningType={WarningType.maxAccounts} />
              )}
              {hasMaxWarning && (
                <WarningBanner
                  isDismissable={true}
                  setShowWarning={setShowMaxWarning}
                  showWarning={showMaxWarning}
                  warningType={WarningType.maxResults}
                />
              )}
              {isAccountListSuccess && (
                <Box fontSize="sm" textAlign="right" mb={4}>
                  <Button
                    isLoading={isPDFLoading}
                    onClick={downloadPDF}
                    loadingText="Loading"
                    variant="capsLink"
                  >
                    CLICK FOR COMPLETE ACCOUNT LISTING
                  </Button>
                </Box>
              )}
            </Box>
            {(isAccountListLoading ||
              isAccountListError ||
              !agentAccountList) && <Loader p={50} />}
            {!isAccountListLoading &&
              !isAccountListError &&
              agentAccountList && (
                <AccountTable
                  height={tableHeight}
                  agentAccountList={agentAccountList}
                  handleCheckbox={handleCheckbox}
                />
              )}
          </Box>
        </ModalBody>
        <ModalFooter>
          <VStack width="100%">
            <Box width="100%" textAlign="right">
              <Button
                onClick={() => {
                  if (totalSelected > 4) {
                    scrollToElement();
                  } else {
                    addAccountUsers();
                  }
                }}
                colorScheme="brand"
                ml={3}
              >
                Select
              </Button>
            </Box>
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AgentsAccountSearchResults;
