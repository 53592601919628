import { Box, Button } from '@chakra-ui/react';
import { TdsField } from '@trv-tds/react';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSaveSAIAlertMutation, useGetSAIAlertsQuery } from '../../../features/emailAlerts/emailAlertApiSlice';
import useAuth from '../../../hooks/useAuth';
import { RootState } from '../../../store';
import { AccIdentification } from '../AgentAccountSearch/agentAccount';

const SAISelectBox = () => {

  const accountIdentificationSelectionsDivRef = useRef<null | HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
  const [saiNumbers, setSaiNumbers] = useState<string[]>([]);
  const [input, setInput] = useState('');
  const [error, setError] = useState('');
  const userAccountIdentification: AccIdentification = useSelector((state: RootState) => state.agentAccount.accountIdentification);
  const { profileData } = useAuth();
  const [showLoader, setShowLoader] = useState(false)

  const queryData = {
    UserInformation: profileData[0],
  };

  const [saveSAIAlerts] = useSaveSAIAlertMutation()
  const { data, isLoading, isFetching } = useGetSAIAlertsQuery(queryData)
  const saiAlerts = data?.LR_EMAILING_INTERNAL?.map((_data) => {
    return _data.SAI_NOS
  })

  useEffect(() => {
    if (userAccountIdentification.accountIdentificationMethod === "SAI Number") {
      setSaiNumbers([...new Set([...saiNumbers, ...userAccountIdentification.accountIdentificationSelections])])
    }
  }, [userAccountIdentification])

  const checkSAI = (input) => {
    var alpha = /^([a-zA-Z])$/;
    return !alpha.test(input.charAt(4));
  }



  const handleAdd = () => {
    if (input.length < 9) {
      setError('SAI Number must be 9 characters.');
    } else if (checkSAI(input)) {
      setError('SAI Number must be 9 characters with alpha in 5th position.');
    } else if (saiNumbers.length < 4) {
      setSaiNumbers([...saiNumbers, input]);
      setInput('');
      setError('');
    } else {
      setError('Max 4 of 4 values entered.');
    }
    setSelectedIndex(null)
  };

  const handleRemoveSelected = () => {
    if (selectedIndex !== null) {
      setSaiNumbers(saiNumbers.filter((number) => number !== saiNumbers[selectedIndex]));
      setSelectedIndex(null)
    }
  };

  const handleClearAll = () => {
    setSaiNumbers([]);
    setError('')
    setInput('')
    setSelectedIndex(null)
  };

  const handleSAISelected = (index: number) => {
    setSelectedIndex(index)
  };

  const saiNumberHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0) {
      setError("")
    }
    const regex = new RegExp("^[a-zA-Z0-9]+$");
    if (regex.test(e.target.value.charAt(e.target.value.length - 1))) {
      setInput(e.target.value.substring(0, 9));
      if (saiNumbers.includes(e.target.value)) {
        setError("Duplicate SAI Number Added")
      } else {
        setError("")
      }
    } else {
      if (e.target.value.length > 0) {
        e.preventDefault();
      } else {
        setInput('');
      }
    }
  };

  const handleEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event && event.key && event.key === 'Enter' && saiNumbers?.length <= 4 && !saiNumbers.includes(input)) {
      handleAdd();
    }
  }

  return (
    <div>
      <div className='sai-inner-div-policy-search'>
        <div className='sai-selection-div-outer'>
          <div className='sai-selection-div-inner'>
            <div
            className='sai-form-policy-input'>
              <TdsField label="Add up to 4 SAI #s" alert={error ? 'error' : 'false'}>
                <input
                  style={{ height: '2rem' }}
                  type='text'
                  aria-label='sai number'
                  value={input}
                  disabled={saiNumbers.length >= 4}
                  onChange={(e) => { saiNumberHandler(e) }}
                  onClick={() => {
                    if (error?.length > 0)
                      setError("")
                  }}
                  onKeyUp={(event) => handleEnterKey(event)}
                />
              </TdsField>
              {error &&
                <div
                  data-testid="sai_error"
                  className="tds-field--error"
                  style={{ marginBottom: 0, marginTop: -20 }}
                >
                  <div aria-atomic='true' className='tds-field__message' role='alert' >
                    {error}
                  </div>
                </div>
              }
            </div>
            <div className='sai-number-add-button-container'>
              <button
                className='tds-button--primary tds-button--small'
                type='button'
                disabled={saiNumbers.length >= 4 || saiNumbers.includes(input)}
                style={{ width: '100%', height: '2rem' }}
                onClick={handleAdd}
              >
                ADD
              </button>
            </div>
          </div>
        </div>

        <div className='sai-number-display-div'>
          <div className='tds-field policy-number-textarea-div' style={{ marginBottom: 0 }}>
            <div
              data-testid="sai-number-data"
              className={`policy-number-display-inner-div ${error && `tds-field__input`}`}
              tabIndex={0}
              aria-label='account identification selections'
              role='list'
              ref={accountIdentificationSelectionsDivRef}
              id='accountIdentificationSelectionsDivID'
              style={{ height: '110px', border: '1px solid #909294', borderRadius: '2%', color: '#46494d', overflow: 'auto' }}
            >
              {saiNumbers.map(function (element, index) {
                return (
                  <Box key={index} onClick={() => handleSAISelected(index)}
                    bgColor={selectedIndex === index ? '#0091eb66' : 'white'}
                    style={{ cursor: 'default', marginBottom: 0 }} role='listitem'>
                    {element}
                  </Box>
                );
              })}
            </div>
          </div>
          <p className='body-xsmall wireframe-p-font-regular' style={{ marginBottom: '0.5rem' }}>
            (Added {saiNumbers.length} of 4)
          </p>
        </div>
        <div className='sai-number-display-buttons-div'>
          <div className='sai-number-button-container'>
            <button
              className='tds-button--secondary tds-button--small sai-number-btns'
              style={{ marginBottom: '1rem' }}
              type='button'
              onClick={handleRemoveSelected}
              disabled={selectedIndex === null}
            >
              REMOVE SELECTED
            </button>
            <button
              className='tds-button--secondary tds-button--small sai-number-btns'
              type='button'
              disabled={!(saiNumbers?.length > 0)}
              onClick={handleClearAll}
            >
              CLEAR ALL
            </button>
          </div>
        </div>
      </div>
      <div className='sai-number-save-alert-div'>
        <Button
          isDisabled={saiNumbers.length === 0}
          isLoading={showLoader}
          variant="primary"
          onClick={async () => {
            setShowLoader(true)
            handleClearAll()
            if (saiAlerts?.includes(saiNumbers.join(","))) {
              setError(`Alert already exists for SAI(s) ${saiNumbers}`)
            } else {
              const data = await saveSAIAlerts({ UserInformation: profileData[0], LossesCriteria: { Selections: saiNumbers, AccountIdType: 4 } })
              // @ts-ignore
              if (!data?.data?.result && data?.data?.messages[0]?.description) {
                // @ts-ignore
                setError(data?.data?.messages[0]?.description)
              } else {
                setError("")
              }
            }
            setShowLoader(false)
          }}

          className='tds-button--primary tds-button--small sai-number-save-alert-btn'
          type='button'>
          SAVE ALERT
        </Button>
      </div>
    </div>
  );
};

export default SAISelectBox
  ;